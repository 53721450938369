import React from "react";
import {encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Helpers } from "./config/Helpers";

import DropdownCart from "./components/DropdownCart";
import ModalProduct from "./pages/Home";

class Header extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessKodeNasabah: (ReactSession.get("sess_kodenasabah")) ? ReactSession.get("sess_kodenasabah") : '',
            setDropdownCart: '',
            totalOrderActive: '0',
            urlActive: window.location.pathname,
            orderType: (ReactSession.get("order_type")) ? ReactSession.get("order_type") : 'Grosir'
        }
    }

    componentDidMount() {
        if(this.state.sessUserCode){
            this.getSummary();
            this.loadDropdownCart();
        }
    }

    getSummary = () => {
        Helpers.getSummary(this.state.sessUserCode, this.state.sessKodeNasabah).then(res => {
            if(res.length > 0){
                this.setState({
                    totalOrderActive: res[0].total_active_order
                });
            }
        })
    };

    loadDropdownCart = () => {
        this.setState({
            setDropdownCart: <DropdownCart />
        })

        setInterval(() => {
            const checkUpdate = ReactSession.get("update_dropdown_cart");

            if(checkUpdate == 't'){
                ReactSession.set("update_dropdown_cart", "f");

                this.setState({
                    setDropdownCart: ''
                }, () => {
                    this.setState({
                        setDropdownCart: <DropdownCart />
                    })
                })
            }
        }, 1000);
    };

    handleRadioChange = (object) => {
        ReactSession.set("order_type", object);

        //window.location.reload();
        window.location.href = window.location.origin + window.location.pathname;
    };

    handleLogout = () => {
        if (window.confirm('Apakah anda yakin akan keluar dari aplikasi?')) {
            ReactSession.set("sess_userid", "");
            ReactSession.set("sess_fullname", "");
            ReactSession.set("sess_branchcode", "");
            ReactSession.set("sess_employeecode", "");
            ReactSession.set("sess_profileid", "");
            ReactSession.set("sess_email", "");
            ReactSession.set("sess_usercode", "");
            ReactSession.set("sess_usertype", "");
            ReactSession.set("sess_uuid", "");
            ReactSession.set("sess_order_kodelokasi", '');
            ReactSession.set("sess_order_jarak", '0');
            ReactSession.set("sess_order_penerima", '');
            ReactSession.set("sess_order_noponsel", '');
            ReactSession.set("sess_order_latitude", '0');
            ReactSession.set("sess_order_longitude", '0');
            ReactSession.set("sess_order_pinpoint", '');
            ReactSession.set("sess_order_jasakirim", '0');
            ReactSession.set("sess_kodenasabah", '');

            window.location.href = './';
        } else {
            console.log('Cancel.');
        }
    };

    render() {
        return (
            <>
                {/*<link rel="stylesheet" href="./assets/css/plugins/animate.min.css"/>*/}
                {/*<link rel="stylesheet" href="./assets/css/main.css?v=3.2"/>*/}
                {/*<link rel="stylesheet" href="./assets/css/custom.css"/>*/}
                {/*<link rel="stylesheet" href="./assets/css/marker.css"/>*/}

                <ToastContainer />
                <header id="mobile-header" className="header-area header-style-1 header-height-2">
                    {/*<div className="mobile-promotion">*/}
                        {/*<span>Grand opening, <strong>up to 15%</strong> off all items. Only <strong>3 days</strong> left</span>*/}
                    {/*</div>*/}
                    <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
                        <div className="container">
                            <div className="header-wrap">
                                <div className="logo logo-width-1">
                                    <a href="./"><img src="./assets/imgs/theme/waroq-logo.png" alt="logo" /></a>
                                </div>
                                <div className="header-right">
                                    <div className="search-style-2">
                                        <form method={'GET'} action="./shop">
                                            <input type="text" name={'search'} placeholder="Cari produk..." style={{maxWidth: 'unset'}} />
                                        </form>
                                    </div>
                                    <div className="header-action-right">
                                        <div className="header-action-2">
                                            <div className="header-action-icon-2">
                                                <a href="./chat">
                                                    <img className="svgInject" alt="Nest" src="./assets/imgs/theme/icons/v2_ic_chat.png" />
                                                </a>
                                                <a href="./chat"><span className="lable">Pusat Bantuan</span></a>
                                            </div>
                                            <div className="header-action-icon-2">
                                                <a href="./account-wishlist">
                                                    <img className="svgInject" alt="Nest" src="./assets/imgs/theme/icons/icon-heart.svg" />
                                                </a>
                                                <a href="./account-wishlist"><span className="lable">Wishlist</span></a>
                                            </div>

                                            { this.state.setDropdownCart }

                                            <div className="header-action-icon-2">
                                                <a href="#">
                                                    <img className="svgInject" alt="Nest" src="./assets/imgs/theme/icons/icon-user.svg" />
                                                </a>
                                                <a href="#"><span className="lable ml-0">Akun</span></a>
                                                <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                                    {
                                                        this.state.sessUserCode !== '' && (
                                                            <ul>
                                                                <li>
                                                                    <a href="./account"><i className="fi fi-rs-user mr-10"></i>Profil & Saldo</a>
                                                                </li>
                                                                <li>
                                                                    <a href="./account-orders" style={{'display': 'contents'}}>
                                                                        <i className="fi fi-rs-shopping-cart mr-10"></i>Order Aktif
                                                                        <div className="badge-circle">{this.state.totalOrderActive}</div>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="./account-history"><i className="fi fi-rs-archive mr-10"></i>Riwayat Transaksi</a>
                                                                </li>
                                                                <li>
                                                                    <a href="./account-voucher"><i className="fi fi-rs-label mr-10"></i>Transfer Saldo</a>
                                                                </li>
                                                                <li>
                                                                    <a href="./account-withdrawal"><i className="fi fi-rs-money mr-10"></i>Penarikan Point</a>
                                                                </li>
                                                                {/*<li>*/}
                                                                    {/*<a href="./account-wishlist"><i className="fi fi-rs-star mr-10"></i>Wishlist</a>*/}
                                                                {/*</li>*/}
                                                                <li>
                                                                    <a onClick={() => {this.handleLogout()}}><i className="fi fi-rs-sign-out mr-10"></i>Keluar</a>
                                                                </li>
                                                            </ul>
                                                        )
                                                    }
                                                    {
                                                        this.state.sessUserCode == '' && (
                                                            <ul>
                                                                <li>
                                                                    <a href={'./login?redirectUrl=' + base64_encode(window.location.href)}><i className="fi fi-rs-user mr-10"></i>Login</a>
                                                                </li>
                                                            </ul>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-bottom header-bottom-bg-color sticky-bar">
                        <div className="container">
                            <div className="header-wrap header-space-between position-relative">
                                <div className="logo logo-width-1 d-block d-lg-none">
                                    {
                                        this.state.urlActive.indexOf('/shop') <= -1 && (
                                            <div className="search-style-2">
                                                <form method={'GET'} action="./shop">
                                                    <input type="text" name={'search'} placeholder="Cari produk..." style={{maxWidth: 'unset'}} />
                                                </form>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.urlActive.indexOf('/shop') > -1 && (
                                            <a href="./"><img src="/assets/imgs/theme/waroq-logo.png" alt="logo" /></a>
                                        )
                                    }

                                    {/*<a href="./"><img src="/assets/imgs/theme/waroq-logo.png" alt="logo" /></a>*/}
                                </div>
                                <div className="header-nav d-none d-lg-flex">
                                    {/*<div className="main-categori-wrap d-none d-lg-block">*/}
                                        {/*<a className="categories-button-active" href="#">*/}
                                            {/*<span className="fi-rs-apps"></span> Jenis Order*/}
                                            {/*<i className="fi-rs-angle-down"></i>*/}
                                        {/*</a>*/}
                                        {/*<div className="categories-dropdown-wrap categories-dropdown-active-large font-heading">*/}
                                            {/*<div className="d-flex categori-dropdown-inner">*/}
                                                {/*<ul id="ul-categories-start">*/}
                                                    {/*<li style={{'height': 'unset'}}>*/}
                                                        {/*<input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={'Grosir'} name="jenisorder_dekstop" checked={((this.state.orderType == 'Grosir') ? 'checked' : '')} onChange={() => {this.handleRadioChange('Grosir')}}/>*/}
                                                        {/*<a>*/}
                                                            {/*<img src={'./assets/imgs/shop/v2_ic_cart.png'} />*/}
                                                            {/*Grosir<br/> Total Belanja<br/> ≥ Rp 500.000*/}
                                                        {/*</a>*/}
                                                    {/*</li>*/}
                                                {/*</ul>*/}
                                                {/*<ul id="ul-categories-end" className="end">*/}
                                                    {/*<li style={{'height': 'unset'}}>*/}
                                                        {/*<input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={'Retail'} name="jenisorder_dekstop" checked={((this.state.orderType == 'Retail') ? 'checked' : '')} onChange={() => {this.handleRadioChange('Retail')}}/>*/}
                                                        {/*<a>*/}
                                                            {/*<img src={'./assets/imgs/shop/v2_ic_basket.png'} />*/}
                                                            {/*Retail<br/> Total Belanja<br/> ≥ Rp 300.000*/}
                                                        {/*</a>*/}
                                                    {/*</li>*/}
                                                {/*</ul>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                    <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                                        <nav>
                                            <ul>
                                                {/*<li className="hot-deals"><img src="./assets/imgs/theme/icons/icon-hot.svg" alt="hot deals" /><a href="./#section-produk-terlaris">Produk Terlaris</a></li>*/}
                                                {/*<li className="hot-deals"><img src="./assets/imgs/theme/icons/icon-hot.svg" alt="hot deals" /><a href="./#section-produk-terbaru">Produk Terbaru</a></li>*/}
                                                <li className="hot-deals">
                                                    {/*<img src="./assets/imgs/navbar/v2_ic_nav_home_grey.png" alt="hot deals" width={24} />*/}
                                                    <a className={(this.state.urlActive === '/') ? 'active' : ''} href="./">Beranda</a>
                                                </li>
                                                <li className="hot-deals">
                                                    {/*<img src="./assets/imgs/navbar/v2_ic_nav_store_grey.png" alt="hot deals" width={24} />*/}
                                                    <a className={(this.state.urlActive === '/store') ? 'active' : ''} href="./store">Warung</a>
                                                </li>
                                                <li className="hot-deals">
                                                    {/*<img src="./assets/imgs/navbar/v2_ic_nav_cart_grey.png" alt="hot deals" width={24} />*/}
                                                    <a className={(this.state.urlActive === '/shop') ? 'active' : ''} href="./shop">Telusuri</a>
                                                </li>
                                                {/*<li className="hot-deals">*/}
                                                    {/*/!*<img src="./assets/imgs/navbar/v2_ic_nav_market_group_grey.png" alt="hot deals" width={24} />*!/*/}
                                                    {/*<a className={(this.state.urlActive === '/patungan') ? 'active' : ''} href="./patungan">Patungan</a>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                    {/*<a className={(this.state.urlActive === '/about') ? 'active' : ''} href="./marketplace">Marketplace</a>*/}
                                                {/*</li>*/}
                                                <li>
                                                    <a className={(this.state.urlActive === '/about') ? 'active' : ''} href="./about">Tentang Waroq</a>
                                                </li>
                                                <li>
                                                    <a className={(this.state.urlActive === '/privacy-policy') ? 'active' : ''} href="./privacy-policy">Kebijakan Privasi</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="header-action-icon-2 d-block d-lg-none">
                                    {/*<div className="burger-icon burger-icon-white">*/}
                                        {/*<span className="burger-icon-top"></span>*/}
                                        {/*<span className="burger-icon-mid"></span>*/}
                                        {/*<span className="burger-icon-bottom"></span>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="header-action-right d-block d-lg-none">
                                    <div className="header-action-2">
                                        <div className="header-action-icon-2">
                                            <a className="mini-cart-icon" href="./account-wishlist">
                                                <img alt="Nest" src="./assets/imgs/theme/icons/icon-heart.svg" />
                                            </a>
                                        </div>
                                        <div className="header-action-icon-2">
                                            <a className="mini-cart-icon" href="./chat">
                                                <img alt="Nest" src="./assets/imgs/theme/icons/v2_ic_chat.png" />
                                            </a>
                                        </div>

                                        { this.state.setDropdownCart }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="mobile-header-active mobile-header-wrapper-style">
                    <div className="mobile-header-wrapper-inner">
                        <div className="mobile-header-top">
                            <div className="mobile-header-logo">
                                <a href="./"><img src="/assets/imgs/theme/waroq-logo.png" alt="logo" /></a>
                            </div>
                            <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                                <button className="close-style search-close">
                                    <i className="icon-top"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>
                        </div>
                        <div className="mobile-header-content-area">
                            {/*<div className="mobile-search search-style-3 mobile-header-border">*/}
                                {/*<form action="#">*/}
                                    {/*<input type="text" placeholder="Cari produk…" />*/}
                                    {/*<button type="submit"><i className="fi-rs-search"></i></button>*/}
                                {/*</form>*/}
                            {/*</div>*/}

                            {/*<div className="categories-dropdown-wrap font-heading">*/}
                                {/*<div className="d-flex categori-dropdown-inner">*/}
                                    {/*<ul id="ul-categories-start">*/}
                                        {/*<li style={{'height': 'unset'}}>*/}
                                            {/*<input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={'Grosir'} name="jenisorder_mobile" checked={((this.state.orderType == 'Grosir') ? 'checked' : '')} onChange={() => {this.handleRadioChange('Grosir')}}/>*/}
                                            {/*<a style={{'padding': '0px', 'font-size': '11px'}}>*/}
                                                {/*<img src={'./assets/imgs/shop/v2_ic_cart.png'} />*/}
                                                {/*Grosir<br/> Total Belanja<br/> ≥ Rp 500.000*/}
                                            {/*</a>*/}
                                        {/*</li>*/}
                                    {/*</ul>*/}
                                    {/*<ul id="ul-categories-end" className="end">*/}
                                        {/*<li style={{'height': 'unset'}}>*/}
                                            {/*<input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={'Retail'} name="jenisorder_mobile" checked={((this.state.orderType == 'Retail') ? 'checked' : '')} onChange={() => {this.handleRadioChange('Retail')}}/>*/}
                                            {/*<a style={{'padding': '0px', 'font-size': '11px'}}>*/}
                                                {/*<img src={'./assets/imgs/shop/v2_ic_basket.png'} />*/}
                                                {/*Retail<br/> Total Belanja<br/> ≥ Rp 300.000*/}
                                            {/*</a>*/}
                                        {/*</li>*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</div>*/}

                            <div className="mobile-menu-wrap mobile-header-border">
                                <nav>
                                    <ul className="mobile-menu font-heading">
                                        <li>
                                            <a className={(this.state.urlActive === '/') ? 'active' : ''} href="./">Beranda</a>
                                        </li>
                                        <li>
                                            <a className={(this.state.urlActive === '/store') ? 'active' : ''} href="./store">Warung</a>
                                        </li>
                                        <li>
                                            <a className={(this.state.urlActive === '/shop') ? 'active' : ''} href="./shop">Belanja</a>
                                        </li>
                                        {/*<li>*/}
                                            {/*<a className={(this.state.urlActive === '/patungan') ? 'active' : ''} href="./patungan">Patungan</a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a className={(this.state.urlActive === '/about') ? 'active' : ''} href="./about">Tentang Waroq</a>
                                        </li>
                                        <li>
                                            <a className={(this.state.urlActive === '/privacy-policy') ? 'active' : ''} href="./privacy-policy">Kebijakan Privasi</a>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="#">Akun</a>
                                            {
                                                this.state.sessUserCode !== '' && (
                                                    <ul className="dropdown">
                                                        <li><a href="./account">Profil & Saldo</a></li>
                                                        <li><a href="./account-orders" style={{'display': 'inline'}}>Order Aktif</a><div className="badge-circle" style={{'display': 'inline-block'}}>{this.state.totalOrderActive}</div></li>
                                                        <li><a href="./account-history">Riwayat Transaksi</a></li>
                                                        <li><a href="./account-voucher">Transfer Saldo</a></li>
                                                        <li><a href="./account-withdrawal">Penarikan Point</a></li>
                                                        {/*<li><a href="./account-wishlist">Wishlist</a></li>*/}
                                                        <li><a onClick={() => {this.handleLogout()}}>Keluar</a></li>
                                                    </ul>
                                                )
                                            }
                                            {
                                                this.state.sessUserCode == '' && (
                                                    <ul className="dropdown">
                                                        <li>
                                                            <a href={'./login?redirectUrl=' + base64_encode(window.location.href)}>Login</a>
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="mobile-header-info-wrap">
                                <div className="single-mobile-header-info">
                                    <a href="#">
                                        <i className="fi-rs-marker"></i>
                                        Mayapada Tower 1 Lt 5, Jl. Jend Sudirman Kav 28, Jakarta Selatan<br/>
                                        PT BINA ANGGOTA ORGANIK
                                    </a>
                                </div>
                                <div className="single-mobile-header-info">
                                    <a href="#"><i className="fi-rs-envelope"></i>admin@waroq.com </a>
                                </div>
                                <div className="single-mobile-header-info">
                                    <a href="#"><i className="fi-rs-headphones"></i>0878-1987-8386 </a>
                                </div>
                            </div>
                            <div className="mobile-social-icon mb-50">
                                <h6 className="mb-15">Ikuti Kami</h6>
                                <a href="https://www.facebook.com/waroqonline/" target={'_blank'}><img src="./assets/imgs/theme/icons/icon-facebook-white.svg" alt=""/></a>
                                <a href="https://twitter.com/waroqonline" target={'_blank'}><img src="./assets/imgs/theme/icons/icon-twitter-white.svg" alt=""/></a>
                                <a href="https://www.instagram.com/waroqonline/" target={'_blank'}><img src="./assets/imgs/theme/icons/icon-instagram-white.svg" alt=""/></a>
                            </div>
                            <p className="font-sm mb-0">&copy; 2024, <strong className="text-brand">Waroq</strong> -
                                Warung Baroqah Online <br/>All rights reserved</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Header;