import React from "react";

import { Helpers } from "../config/Helpers"
import {ReactSession} from "react-client-session";
import {toast} from "react-toastify";
import queryString from "query-string";
import {decode as base64_decode, encode as base64_encode} from "base-64";

class Login extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            urlParam: queryString.parse(window.location.search),
            email: '',
            password: ''
        }
    }

    componentDidMount() {
        if(this.state.sessUserCode){
            window.location.href = './';
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        Helpers.getLoginData(this.state.email, this.state.password, '',ReactSession.get("sess_email")).then(res => {
            if(res.length > 0){
                const r = res[0];

                ReactSession.set("sess_userid", r.UserId);
                ReactSession.set("sess_fullname", r.UserFullName);
                ReactSession.set("sess_branchcode", r.BranchCode);
                ReactSession.set("sess_employeecode", r.EmployeeCode);
                ReactSession.set("sess_profileid", r.UserProfileId);
                ReactSession.set("sess_email", r.Email);
                ReactSession.set("sess_usercode", r.KodeUser);
                ReactSession.set("sess_usertype", r.UserType);
                ReactSession.set("sess_uuid", r.UUID);
                ReactSession.set("sess_password", this.state.password);

                this.setState({
                    email: '',
                    password: ''
                });

                const parUrl = (this.state.urlParam.redirectUrl) ? base64_decode(this.state.urlParam.redirectUrl) : '';
                if(this.state.urlParam.voucher){
                    window.location.href = '/?voucher='+this.state.urlParam.voucher;
                }else{
                    window.location.href = parUrl;
                }
            }else{
                toast.error(Helpers.MSG_ERROR_LOGIN);
            }
        })
    };

    render() {
        return (
            <>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Login
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-30 pb-150">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                                    <div className="row">
                                        <div className="col-lg-6 pr-30 d-none d-lg-block">
                                            <img className="border-radius-15" src="assets/imgs/page/bg-auth.jpg"
                                                 alt=""/>
                                        </div>
                                        <div className="col-lg-6 col-md-8">
                                            <div className="login_wrap widget-taber-content background-white">
                                                <div className="padding_eight_all bg-white">
                                                    <div className="heading_s1">
                                                        <h1 className="mb-5">Login</h1>
                                                        <p className="mb-30">Belum memiliki akun? <a
                                                            href={(this.state.urlParam.voucher) ? './register?voucher='+this.state.urlParam.voucher : './register'}>Daftar disini</a></p>
                                                    </div>
                                                    <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                                        <div className="form-group">
                                                            <input required type="text" value={this.state.email} onChange={e => this.setState({email: e.target.value})}
                                                                   placeholder="Email/No Telepon/Kode Warung *"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <input required type="password" value={this.state.password} onChange={e => this.setState({password: e.target.value})}
                                                                   placeholder="Password *"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit"
                                                                    className="btn btn-heading btn-block hover-up"
                                                                    name="login">Masuk
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default Login;