import React from "react";

import { Helpers } from "../../config/Helpers";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import {toast} from "react-toastify";
import $ from "jquery";

class Profile extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessKodeNasabah: (ReactSession.get("sess_kodenasabah")) ? ReactSession.get("sess_kodenasabah") : '',
            summaryData: '',
            profileData: '',
            bankData: [],
            fullname: '',
            handphone: '',
            alamat: '',
            bank: '',
            pemilik: '',
            rekening: '',
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getSummary();
            this.getAccountInfo();
            this.getBank();
        }
    }

    getSummary = () => {
        Helpers.getSummary(this.state.sessUserCode, this.state.sessKodeNasabah).then(res => {
            this.setState({
                summaryData: res[0]
            });
        })
    };

    getBank = () => {
        Helpers.getBank().then(res => {
            this.setState({
                bankData: res
            });
        })
    };

    getAccountInfo = () => {
        Helpers.getAccountInfo(this.state.sessEmail).then(res => {
            this.setState({
                profileData: res[0],
                fullname: res[0].NamaMarketing,
                handphone: res[0].Telepon,
                alamat: res[0].Alamat,
                bank: res[0].KodeBank,
                pemilik: res[0].NamaRekening,
                rekening: res[0].NoRekening
            });
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        Helpers.changePassword(this.state.sessUserCode, this.state.old, this.state.new, this.state.confirm).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.setState({
                        old: '',
                        new: '',
                        confirm: ''
                    });
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    handleSubmitProfile = (e) => {
        e.preventDefault();

        Helpers.updateProfileMarketing(
            this.state.sessUserCode,
            this.state.fullname,
            this.state.alamat,
            this.state.handphone,
            this.state.sessEmail,
            this.state.bank,
            this.state.pemilik,
            this.state.rekening,
            this.state.sessEmail
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.setState({
                        summaryData: '',
                        profileData: '',
                        bankData: [],
                        fullname: '',
                        handphone: '',
                        alamat: '',
                        bank: '',
                        pemilik: '',
                        rekening: '',
                    });

                    this.getSummary();
                    this.getAccountInfo();
                    this.getBank();
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    handleBBXPoinLogout = () => {
        if (window.confirm('Apakah anda yakin akan keluar dari BBX Poin?')) {
            ReactSession.set("sess_kodenasabah", '');

            window.location.reload();
        } else {
            console.log('Cancel.');
        }
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Akun <span></span> Profil & Saldo
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-10">

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 m-auto">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="dashboard-menu mb-30">
                                                <ul className="nav flex-column" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="dashboard-tab"
                                                           data-bs-toggle="tab" href="#dashboard" role="tab"
                                                           aria-controls="dashboard" aria-selected="false"><i
                                                            className="fi-rs-settings-sliders mr-10"></i>Dashboard</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="orders-tab" data-bs-toggle="tab"
                                                           href="#orders" role="tab" aria-controls="orders"
                                                           aria-selected="false"><i className="fi-rs-user mr-10"></i>Ubah
                                                            Profil</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="track-orders-tab"
                                                           data-bs-toggle="tab" href="#track-orders" role="tab"
                                                           aria-controls="track-orders" aria-selected="false"><i
                                                            className="fi-rs-key mr-10"></i>Ubah Password</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="tab-content account dashboard-content">
                                                <div className="tab-pane fade active show" id="dashboard"
                                                     role="tabpanel" aria-labelledby="dashboard-tab">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h3 className="mb-10">Hello {this.state.summaryData.fullname}!</h3>
                                                            <h6 className="mb-0">{this.state.sessEmail}</h6>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row mt-25">
                                                                <div
                                                                    className="col-lg-1-3 col-md-4 col-12 col-sm-6 mb-md-4 mb-xl-0">
                                                                    <div
                                                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp banner-split-img"
                                                                        data-wow-delay="0">
                                                                        <div className="banner-icon">
                                                                            <img
                                                                                src="assets/imgs/icon/v2_ic_credit.png"
                                                                                alt=""/>
                                                                        </div>
                                                                        <div className="banner-text">
                                                                            <h3 className="icon-box-title">Limit Kredit</h3>
                                                                            <p>Rp {this.state.summaryData.credit_limit}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-1-3 col-md-4 col-12 col-sm-6">
                                                                    <div
                                                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp banner-split-img"
                                                                        data-wow-delay=".1s">
                                                                        <div className="banner-icon">
                                                                            <img
                                                                                src="assets/imgs/icon/v2_ic_wallet_solid_a.png"
                                                                                alt=""/>
                                                                        </div>
                                                                        <div className="banner-text">
                                                                            <h3 className="icon-box-title">Voucher Waroq</h3>
                                                                            <p>Rp {this.state.summaryData.saldo_waroq}</p>
                                                                            <a href={'./account-mutasi-voucher'}>Lihat Mutasi</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-1-3 col-md-4 col-12 col-sm-6">
                                                                    <div
                                                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp banner-split-img"
                                                                        data-wow-delay=".2s">
                                                                        <div className="banner-icon">
                                                                            <img
                                                                                src="assets/imgs/icon/v2_ic_commission_solid.png"
                                                                                alt=""/>
                                                                        </div>
                                                                        <div className="banner-text">
                                                                            <h3 className="icon-box-title">Waroq Poin</h3>
                                                                            <p>Rp {this.state.summaryData.commission_now}</p>
                                                                            <a href={'./account-mutasi-komisi'}>Lihat Mutasi</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-1-3 col-md-4 col-12 col-sm-6 mt-3">
                                                                    <div
                                                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp banner-split-img"
                                                                        data-wow-delay=".2s">
                                                                        <div className="banner-icon">
                                                                            <img
                                                                                src="assets/imgs/icon/bbx_poin_round.png"
                                                                                alt=""/>
                                                                        </div>
                                                                        <div className="banner-text">
                                                                            <h3 className="icon-box-title">BBX Poin</h3>
                                                                            <p>Rp {this.state.summaryData.bbx_poin_format}</p>
                                                                            {
                                                                                !this.state.sessKodeNasabah && (
                                                                                    <a href={'./bbx-poin-login?redirectUrl='+ base64_encode(window.location.href)}>Hubungkan</a>
                                                                                )
                                                                            }
                                                                            {
                                                                                this.state.sessKodeNasabah && (
                                                                                    <a href="javascript:void(0);" onClick={() => {this.handleBBXPoinLogout()}}>Batalkan</a>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*<div className="col-lg-1-3 col-md-4 col-12 col-sm-6 mt-3" style={{'display': 'none'}}>*/}
                                                                    {/*<div*/}
                                                                        {/*className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp banner-split-img"*/}
                                                                        {/*data-wow-delay=".2s">*/}
                                                                        {/*<div className="banner-icon">*/}
                                                                            {/*<img*/}
                                                                                {/*src="assets/imgs/icon/v2_logo_koinworks.png"*/}
                                                                                {/*alt=""/>*/}
                                                                        {/*</div>*/}
                                                                        {/*<div className="banner-text">*/}
                                                                            {/*<h3 className="icon-box-title">KoinWorks</h3>*/}
                                                                            {/*<p>Rp {this.state.summaryData.KoinWorksLimitFormat}</p>*/}
                                                                            {/*{*/}
                                                                                {/*this.state.summaryData.KoinWorksStatus === '-' && (*/}
                                                                                    {/*<a href={'./koinworks-apply?kode='+this.state.sessUserCode}>Apply Limit</a>*/}
                                                                                {/*)*/}
                                                                            {/*}*/}
                                                                            {/*{*/}
                                                                                {/*this.state.summaryData.KoinWorksStatus === 'Waiting For Documents' && (*/}
                                                                                    {/*<a href={'./koinworks-apply-otp?kode='+this.state.sessUserCode}>Verifikasi OTP Limit</a>*/}
                                                                                {/*)*/}
                                                                            {/*}*/}
                                                                            {/*{*/}
                                                                                {/*(this.state.summaryData.KoinWorksStatus === 'Requested' || this.state.summaryData.KoinWorksStatus === 'Assessment' || this.state.summaryData.KoinWorksStatus === 'Confirmed' || this.state.summaryData.KoinWorksStatus === 'Rejected') && (*/}
                                                                                    {/*<p>{this.state.summaryData.KoinWorksStatus}</p>*/}
                                                                                {/*)*/}
                                                                            {/*}*/}
                                                                            {/*{*/}
                                                                                {/*this.state.summaryData.KoinWorksStatus === 'Approved' && (*/}
                                                                                    {/*<a href={'./koinworks-loan?kode='+this.state.sessUserCode}>Lihat Data Tagihan</a>*/}
                                                                                {/*)*/}
                                                                            {/*}*/}
                                                                        {/*</div>*/}
                                                                    {/*</div>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="orders" role="tabpanel"
                                                     aria-labelledby="orders-tab">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h3 className="mb-0">Ubah Profil</h3>
                                                        </div>
                                                        <div className="card-body contact-from-area">
                                                            <p>Pastikan untuk mengisi data dengan benar sebelum
                                                                melakukan submit.</p>
                                                            <div className="row">
                                                                <div className="col-lg-8">
                                                                    <form className="contact-form-style mt-30 mb-30"
                                                                          method="post" onSubmit={(e) => {this.handleSubmitProfile(e)}}>
                                                                        <div className="input-style mb-20">
                                                                            <label>Nama Lengkap</label>
                                                                            <input value={this.state.fullname} onChange={e => this.setState({fullname: e.target.value})}
                                                                                   placeholder="Masukkan Nama"
                                                                                   type="text" required/>
                                                                        </div>
                                                                        <div className="input-style mb-20">
                                                                            <label>Nomor Handphone</label>
                                                                            <input value={this.state.handphone} onChange={e => this.setState({handphone: e.target.value})}
                                                                                   min={0}
                                                                                   placeholder="Masukkan Nomor Handphone"
                                                                                   type="number" required/>
                                                                        </div>
                                                                        <div className="input-style mb-20">
                                                                            <label>Alamat</label>
                                                                            <input value={this.state.alamat} onChange={e => this.setState({alamat: e.target.value})}
                                                                                   placeholder="Masukkan Alamat"
                                                                                   type="text" required/>
                                                                        </div>
                                                                        <div className="input-style mb-20">
                                                                            <label>Email</label>
                                                                            <input value={this.state.sessEmail}
                                                                                   placeholder="Masukkan Email"
                                                                                   type="email" readOnly={true}/>
                                                                        </div>
                                                                        <div className="input-style mb-20">
                                                                            <label>Nama Bank</label>
                                                                            <div className="custom_select">
                                                                                <select
                                                                                    className="select-style" required onChange={e => this.setState({bank: e.target.value})}>
                                                                                    <option value={''}>Pilih Bank</option>
                                                                                    {
                                                                                        this.state.bankData.map((object, i) => {
                                                                                            return <option value={object.KodeBank} selected={(this.state.bank == object.KodeBank) ? 'selected' : ''}>{object.Fullname}</option>
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-style mb-20">
                                                                            <label>Nama Pemilik Rekening</label>
                                                                            <input value={this.state.pemilik} onChange={e => this.setState({pemilik: e.target.value})}
                                                                                   placeholder="Masukkan Nama Pemilik Rekening"
                                                                                   type="text" required/>
                                                                        </div>
                                                                        <div className="input-style mb-20">
                                                                            <label>Nomor Rekening</label>
                                                                            <input value={this.state.rekening} onChange={e => this.setState({rekening: e.target.value})}
                                                                                   min={0}
                                                                                   placeholder="Masukkan Nomor Rekening"
                                                                                   type="number" required/>
                                                                        </div>
                                                                        <button className="submit submit-auto-width"
                                                                                type="submit">Submit
                                                                        </button>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="track-orders" role="tabpanel"
                                                     aria-labelledby="track-orders-tab">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h3 className="mb-0">Ubah Password</h3>
                                                        </div>
                                                        <div className="card-body contact-from-area">
                                                            <p>Pastikan untuk mengisi data dengan benar sebelum
                                                                melakukan submit.</p>
                                                            <div className="row">
                                                                <div className="col-lg-8">
                                                                    <form className="contact-form-style mt-30 mb-30"
                                                                          method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                                                        <div className="input-style mb-20">
                                                                            <label>Password Lama</label>
                                                                            <input value={this.state.old} onChange={e => this.setState({old: e.target.value})}
                                                                                   placeholder="Masukkan Password"
                                                                                   type="password" required/>
                                                                        </div>
                                                                        <div className="input-style mb-20">
                                                                            <label>Password Baru</label>
                                                                            <input value={this.state.new} onChange={e => this.setState({new: e.target.value})}
                                                                                   placeholder="Masukkan Password"
                                                                                   type="password" required/>
                                                                        </div>
                                                                        <div className="input-style mb-20">
                                                                            <label>Ulangi Password Baru</label>
                                                                            <input value={this.state.confirm} onChange={e => this.setState({confirm: e.target.value})}
                                                                                   placeholder="Masukkan Password"
                                                                                   type="password" required/>
                                                                        </div>
                                                                        <button className="submit submit-auto-width"
                                                                                type="submit">Submit
                                                                        </button>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </>
        );
    }
}

export default Profile;