import React from "react";

import { Helpers } from "../config/Helpers";
import {ReactSession} from "react-client-session";

import ModalProduct from "../components/ModalProduct";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import ReactPaginate from 'react-paginate';
import queryString from "query-string";
import {toast} from "react-toastify";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

class Home extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessFullname: (ReactSession.get("sess_fullname")) ? ReactSession.get("sess_fullname") : '',
            urlParam: queryString.parse(window.location.search),
            categoryData: [],
            productData: [],
            pageNumList: [10,25,50,100,150,200],
            sortData: ['Featured','Price: Low to High','Price: High to Low'],
            sortDataValue: 'Featured',
            offset: 0,
            perPage: 10,
            currentPage: 0,
            setModal: '',
            branchData: [],
            bannerData: [],
            filterCategories: '',
            search: '',
            kodeMarketPlaceDetail: '',
            orderType: (ReactSession.get("order_type")) ? ReactSession.get("order_type") : 'Grosir',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            branchName: (ReactSession.get("branch_name")) ? ReactSession.get("branch_name") : 'JAKARTA',
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount() {
        this.prepareData();

        if(this.state.urlParam.voucher){
            if(!this.state.sessUserCode){
                window.location.href = './login?redirectUrl=' + base64_encode(window.location.href) + '&voucher=' + this.state.urlParam.voucher;
            }else{
                this.applyVoucher();
            }
        }
    }

    applyVoucher = () => {
        Helpers.applyVoucher(this.state.urlParam.voucher, this.state.sessUserCode).then(res => {
            toast[res[0].alert](res[0].message);
        })
    };

    prepareData = () => {
        this.getDetailPaymentInfo();
        this.getListOrderPickupInfo();
        //this.getMarketListCheck();
        //this.getMarketListCancelCheck();
        //this.getBranch();
        this.getPromoBanner();
        //this.getGoodsType();
        this.getProductSkeleton();

        if(this.state.urlParam.search){
            this.setState({
                search: this.state.urlParam.search,
            }, () => {
                this.getProduct(true);
            });
        }else{
            this.getProduct(false);
        }
    };

    getGoodsType = () => {
        Helpers.getGoodsType().then(res => {
            this.setState({ categoryData: res });
        })
    };

    getPromoBanner = () => {
        Helpers.getPromoBanner(this.state.sessUserCode,this.state.branchCode).then(res => {
            this.setState({ bannerData: res });
        })
    };

    getBranch = () => {
        Helpers.getCabang('').then(res => {
            this.setState({ branchData: res });
        })
    };

    getDetailPaymentInfo = () => {
        Helpers.getDetailPaymentInfo(this.state.sessUserCode).then(res => {
            let showDataUnpaid = '';
            if(res.length > 0){
                showDataUnpaid = res.map((object, i) => {
                    return <div className="col-lg-3 col-md-12 mb-5 mt-10">
                        <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                            <img src="./assets/imgs/banner/banner-info.png" alt=""/>
                            <div className="banner-text banner-text-info">
                                <h4>
                                    Belum Dibayar: {object.KodeTransaksi}
                                </h4>
                                {/*<p style={{'font-size': '0.7rem', 'line-height': '15px', 'margin-bottom': '5px'}}>Sisa waktu: </p>*/}
                                <a href={'./account-orders-details?trxid=' + base64_encode(object.KodeTransaksi)} className="btn btn-xs" style={{'padding': '3px 8px 3px 12px', 'font-size': '10px'}}>Lanjutkan Pembayaran <i
                                    className="fi-rs-arrow-small-right"></i></a>
                            </div>
                            <div className="blink-image">
                                <img src="./assets/imgs/shop/v2_img_wallet.png" className="img"/>
                            </div>
                        </div>
                    </div>
                });
            }

            this.setState({
                showDataUnpaid
            })
        })
    };

    getListOrderPickupInfo = () => {
        Helpers.getListOrderPickupInfo(this.state.sessUserCode).then(res => {
            let showDataPickup = '';
            if(res.length > 0){
                showDataPickup = res.map((object, i) => {
                    return <div className="col-lg-3 col-md-12 mb-5 mt-10">
                        <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                            <img src="./assets/imgs/banner/banner-info.png" alt=""/>
                            <div className="banner-text banner-text-info">
                                <h4>
                                    Pembayaran Diterima: {object.KodeTransaksiOrder}
                                </h4>
                                <p style={{'font-size': '0.7rem', 'line-height': '15px', 'margin-bottom': '5px'}}>QRCode diperlukan untuk pengambilan barang</p>
                                <a href={'./account-orders-details?trxid=' + base64_encode(object.KodeTransaksiOrder)} className="btn btn-xs" style={{'padding': '3px 8px 3px 12px', 'font-size': '10px'}}>Tampilkan QRCode <i
                                    className="fi-rs-arrow-small-right"></i></a>
                            </div>
                            <div className="blink-image">
                                <img src="./assets/imgs/shop/v2_img_qrcode.png" className="img"/>
                            </div>
                        </div>
                    </div>
                });
            }

            this.setState({
                showDataPickup
            })
        })
    };

    getMarketListCheck = () => {
        Helpers.getMarketListCheck(this.state.sessEmail,this.state.sessUserCode).then(res => {
            let showDataMarketActive = '';
            if(res.length > 0){
                showDataMarketActive = res.map((object, i) => {
                    return <div className="col-lg-3 col-md-12 mb-5 mt-10">
                        <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                            <img src="./assets/imgs/banner/banner-info.png" alt=""/>
                            <div className="banner-text banner-text-info">
                                <h4>
                                    Total Patungan Aktif: {object.KodeMarketPlace}
                                </h4>
                                <p style={{'font-size': '0.7rem', 'line-height': '15px', 'margin-bottom': '5px'}}>Order akan diproses setelah patungan belanja terkumpul</p>
                                <a href={'./patungan-list?v=user'} className="btn btn-xs" style={{'padding': '3px 8px 3px 12px', 'font-size': '10px'}}>Lihat Data Patungan <i
                                    className="fi-rs-arrow-small-right"></i></a>
                            </div>
                            <div className="blink-image">
                                <img src="./assets/imgs/shop/v2_ic_patungan.png" className="img"/>
                            </div>
                        </div>
                    </div>
                });
            }

            this.setState({
                showDataMarketActive
            })
        })
    };

    getMarketListCancelCheck = () => {
        Helpers.getMarketListCancelCheck(this.state.sessEmail,this.state.sessUserCode).then(res => {
            let showDataMarketRefund = '';
            if(res.length > 0){
                showDataMarketRefund = res.map((object, i) => {
                    return <div className="col-lg-3 col-md-12 mb-5 mt-10">
                        <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                            <img src="./assets/imgs/banner/banner-info.png" alt=""/>
                            <div className="banner-text banner-text-info">
                                <h4>
                                    Total Patungan Refund: {object.KodeMarketPlace}
                                </h4>
                                <p style={{'font-size': '0.7rem', 'line-height': '15px', 'margin-bottom': '5px'}}>Patungan belanja dibatalkan karena tidak terkumpul hingga batas waktu yang ditentukan</p>
                                <a href={'./patungan-list?v=cancel'} className="btn btn-xs" style={{'padding': '3px 8px 3px 12px', 'font-size': '10px'}}>Lihat Data Patungan <i
                                    className="fi-rs-arrow-small-right"></i></a>
                            </div>
                            <div className="blink-image">
                                <img src="./assets/imgs/shop/v2_ic_patungan.png" className="img"/>
                            </div>
                        </div>
                    </div>
                });
            }

            this.setState({
                showDataMarketRefund
            })
        })
    };

    getListReorder = () => {
        Helpers.getListReorder(this.state.sessUserCode).then(res => {

        })
    };

    getProduct = (change) => {
        if(this.state.productData.length > 0){
            if(change){
                Helpers.getProductInfo("1", "10", "", this.state.branchCode).then(res => {
                    this.prepareProduct(res);
                })
            }else{
                this.prepareProduct(this.state.productData);
            }
        }else{
            Helpers.getProductInfo("1", "10", "", this.state.branchCode).then(res => {
                this.prepareProduct(res);
            })
        }
    };

    prepareProduct = (res) => {
        this.setState({
            productData: res,
            kodeMarketPlaceDetail: (this.state.urlParam.joinid) ? base64_decode(this.state.urlParam.joinid) : '',
        });

        let sortData = res;
        if(this.state.search !== ''){
            var str = this.state.search.toUpperCase();
            var words = str.split(" ");
            for (var i = 0; i < words.length; i++) {
                sortData = sortData.filter(item => {
                    const textData = words[i];

                    return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
                });
            }

            this.scroll('#productEl');
        }
        if(this.state.sortDataValue == 'Price: High to Low'){
            sortData = sortData.sort(function(a,b) {
                const val_a = parseInt(a.HargaGrosirFormatTerkecil.replaceAll('.',''));
                const val_b = parseInt(b.HargaGrosirFormatTerkecil.replaceAll('.',''));

                return (val_a < val_b) ? 1 : ((val_b < val_a) ? -1 : 0);
            });
        }
        if(this.state.sortDataValue == 'Price: Low to High'){
            sortData = sortData.sort(function(a,b) {
                const val_a = parseInt(a.HargaGrosirFormatTerkecil.replaceAll('.',''));
                const val_b = parseInt(b.HargaGrosirFormatTerkecil.replaceAll('.',''));

                return (val_a > val_b) ? 1 : ((val_b > val_a) ? -1 : 0);
            });
        }

        const slice = sortData.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = slice.map((object, i) => {
            return <div className="col-lg-1-5 col-md-4 col-6 col-sm-6">
                <div className="product-cart-wrap mb-30">
                    <div className="product-img-action-wrap">
                        <div className="product-img product-img-zoom">
                            <a href="#">
                                <img className="default-img square-img"
                                     src={object.img_url} alt=""/>
                                <img className="hover-img"
                                     src={object.img_url} alt=""/>
                            </a>
                        </div>
                    </div>
                    <div className="product-content-wrap">
                        <div className="product-category">
                            <p style={{'font-size': '10px', 'line-height': '13px', 'margin-top': '5px', 'height': '26px'}}>{object.JenisBarang}</p>
                        </div>
                        <h2 style={{'font-size': '12px', 'height': '44px'}}><a href="#">{object.NamaBarangShort}</a></h2>
                        <div>
                                <span className="font-small text-muted" style={{'font-size': '11px'}}>
                                    Unit: <a href="#">{object.UnitBarangTerkecil}</a>
                                </span>
                        </div>
                        <div className="product-card-bottom" style={{'margin-top': 'unset'}}>
                            {
                                this.state.orderType == 'Grosir' && (
                                    <div className="product-price">
                                        <span style={{'font-size': '15px'}}>{object.HargaGrosirFormatTerkecil} </span>
                                        <span style={{'font-size': '12px'}} className="old-price">{object.HargaRetailFormatTerkecil}</span>
                                    </div>
                                )
                            }
                            {
                                this.state.orderType == 'Retail' && (
                                    <div className="product-price">
                                        <span style={{'font-size': '15px'}}>{object.HargaRetailFormatTerkecil} </span>
                                    </div>
                                )
                            }

                        </div>

                        <a className="btn btn-small w-100 hover-up mt-5" onClick={() => {this.handleProductClick(object)}}>
                            <i className="fi-rs-shopping-cart mr-5"></i>Add
                        </a>
                    </div>
                </div>
            </div>
        })

        this.setState({
            pageCount: Math.ceil(sortData.length / this.state.perPage),
            postData
        });
    };

    getProductSkeleton = () => {
        const totalSkeleton = [1,2,3,4,5];
        const postDataSkeleton = totalSkeleton.map((object, i) => {
            return <div className="col-lg-1-5 col-md-4 col-6 col-sm-6">
                <div className="product-cart-wrap mb-30">
                    <div className="product-img-action-wrap">
                        <Skeleton height={100} />
                    </div>
                    <div className="product-content-wrap">
                        <div className="product-category">
                            <p style={{'font-size': '10px', 'line-height': '13px', 'margin-top': '5px', 'height': '26px'}}><Skeleton /></p>
                        </div>
                        <h2 style={{'font-size': '12px', 'height': '44px'}}><a href="#"><Skeleton /></a></h2>
                        <div>
                            <span className="font-small text-muted" style={{'font-size': '11px'}}>
                                Unit: <a href="#"><Skeleton /></a>
                            </span>
                        </div>
                        <div className="product-card-bottom" style={{'margin-top': 'unset'}}>
                            <div className="product-price">
                                <span style={{'font-size': '15px'}}><Skeleton /></span>
                                <span style={{'font-size': '12px'}} className="old-price"><Skeleton /></span>
                            </div>
                        </div>

                        <a className="btn btn-small w-100 hover-up mt-5" href="#">
                            <i className="fi-rs-shopping-cart mr-5"></i>Add
                        </a>
                    </div>
                </div>
            </div>
        });

        this.setState({
            postDataSkeleton
        });
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getProduct(false)
        });

    };

    handlePageNumClick = (num) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            perPage: num,
        }, () => {
            this.getProduct(false)
        });
    };

    handleSortByClick = (sortBy) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            sortDataValue: sortBy
        }, () => {
            this.getProduct(false)
        });
    };

    handleProductClick = (object) => {
        ReactSession.set("product_id", object.KodeBarang);
        ReactSession.set("product_name", object.NamaBarang);
        ReactSession.set("product_category", object.JenisBarangShort);
        ReactSession.set("product_image", object.img_url);

        this.setState({
            setModal: ''
        }, () => {
            this.setState({
                setModal: <ModalProduct modalRef={true}/>
            })
        })
    };

    handleCategoriesClick = (categories) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            filterCategories: categories,
        }, () => {
            this.getProduct(true)
        });
    };

    handleSearch = (e) => {
        e.preventDefault();

        this.setState({
            currentPage: 0,
            offset: 0
        }, () => {
            this.getProduct(true)
        });
    };

    handleRadioChange = (object) => {
        ReactSession.set("order_type", object);

        window.location.href = window.location.origin + window.location.pathname;
    };

    handleBranchByClick = (object) => {
        ReactSession.set("branch_code", object.KodeCabang);
        ReactSession.set("branch_name", object.NamaCabang);
        ReactSession.set("sess_order_pinpoint", '');
        ReactSession.set("sess_order_jarak", '0');
        ReactSession.set("sess_order_jasakirim", '0');

        window.location.reload();
    };

    scroll = (el) => {
        const section = document.querySelector(el);
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    render() {
        return (
            <>
                { this.state.setModal }

                <main className="main">
                    {
                        (this.state.sessUserCode) && (
                            <section className="banners">
                                <div className="container">
                                    <div className="row">
                                        {this.state.showDataUnpaid}
                                        {this.state.showDataPickup}
                                        {this.state.showDataMarketActive}
                                        {this.state.showDataMarketRefund}
                                    </div>
                                </div>
                            </section>
                        )
                    }

                    <section className="home-slider position-relative">
                        <div className="container">
                            <div className="home-slide-cover mt-15 content-desktop">
                                <div className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
                                    {
                                        this.state.bannerData.length > 0 && (
                                            this.state.bannerData.map((object, i) => {
                                                return (object.Jenis === 'promo') ?
                                                    <a className="single-hero-slider single-animation-wrap" style={{backgroundImage: `url(${object.img_url})`, 'cursor': 'pointer'}} href={object.url} />
                                                       :
                                                    <div className="single-hero-slider single-animation-wrap" style={{backgroundImage: `url(./assets/imgs/banner/bg-banner-waroq-plain.jpeg)`}}>
                                                        <div className="slider-content" style={{width: '100%'}}>
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6">
                                                                    <h1 className="display-2 mb-40" style={{'color': '#FECD08'}}>
                                                                        {object.NamaBarang}
                                                                    </h1>
                                                                    <div id={'product-cart-wrap-slide'} className="product-cart-wrap" style={{'background-color': 'unset', 'border': 'unset'}}>
                                                                        <div className="product-content-wrap" style={{'padding': 'unset'}}>
                                                                            <div className="product-price mt-10">
                                                                                <span style={{'font-size': '50px', 'color': '#FFF'}}>{object.HargaGrosirFormatTerkecil} </span>
                                                                                <span className="old-price" style={{'font-size': '30px', 'color': '#FFF'}}>{object.HargaRetailFormatTerkecil}</span>
                                                                                <span style={{'font-size': '30px', 'color': '#FFF'}}> / {object.UnitBarangTerkecil}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <center>
                                                                        <img src={object.img_url} style={{width: '300px', 'margin-right': '40px', 'margin-top': '15px'}} />
                                                                    </center>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            })
                                        )
                                    }
                                </div>
                                <div className="slider-arrow hero-slider-1-arrow"></div>
                            </div>

                            <div className="home-slide-cover mt-15 content-mobile" style={{'height': '230px'}}>
                                <div className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1" style={{'height': '230px'}}>
                                    {
                                        this.state.bannerData.length > 0 && (
                                            this.state.bannerData.map((object, i) => {
                                                return (object.Jenis === 'promo') ?
                                                    <a className="single-hero-slider single-animation-wrap" style={{backgroundImage: `url(${object.img_url})`, 'cursor': 'pointer', 'height': '230px'}} href={object.url} />
                                                    :
                                                    <div className="single-hero-slider single-animation-wrap"
                                                         style={{backgroundImage: `url(./assets/imgs/banner/bg-banner-waroq-plain.jpeg)`}}>
                                                        <div className="slider-content" style={{width: '100%'}}>
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <h1 className="display-2" style={{'font-size': '15px', 'color': '#FECD08'}}>
                                                                        {object.NamaBarang}
                                                                    </h1>
                                                                    <div id={'product-cart-wrap-slide'} className="product-cart-wrap" style={{'background-color': 'unset', 'border': 'unset'}}>
                                                                        <div className="product-content-wrap" style={{'padding': 'unset'}}>
                                                                            <div className="product-price" style={{'padding-top': '0px'}}>
                                                                                <span style={{'font-size': '15px', 'color': '#FFF'}}>{object.HargaGrosirFormatTerkecil} </span>
                                                                                <span className="old-price" style={{'font-size': '13px', 'color': '#FFF'}}>{object.HargaRetailFormatTerkecil}</span>
                                                                                <span style={{'font-size': '13px', 'color': '#FFF'}}> / {object.UnitBarangTerkecil}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <center>
                                                                        <img src={object.img_url} style={{width: '80px'}} />
                                                                    </center>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            })
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="popular-categories section-padding" style={{'padding-bottom': '0px'}}>
                        <div className="container wow animate__animated animate__fadeIn">
                            <div className="archive-header-2 text-center" style={{'display': 'none'}}>
                                <div className="row">
                                    <div className="col-lg-5 mx-auto">
                                        <div className="sidebar-widget-2 widget_search mb-30">
                                            <div className="search-form">
                                                <form method="post" onSubmit={(e) => {this.handleSearch(e)}}>
                                                    <input type="text" placeholder="Cari produk ..." value={this.state.search} onChange={e => this.setState({search: e.target.value})}/>
                                                    <button type="submit"><i className="fi-rs-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                !this.state.kodeMarketPlaceDetail && (
                                    <div className="archive-header-2 text-center" style={{'display': 'none'}}>
                                        <div className="row">
                                            <div className="col-lg-4 mx-auto">
                                                <div className="categories-dropdown-wrap font-heading">
                                                    <div className="d-flex categori-dropdown-inner">
                                                        <ul id="ul-categories-start">
                                                            <li style={{'height': 'unset'}} onClick={() => {this.handleRadioChange('Grosir')}}>
                                                                <input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={'Grosir'} name="jenisorder_mobile" checked={((this.state.orderType == 'Grosir') ? 'checked' : '')} />
                                                                <a style={{'padding': '0px', 'font-size': '9px'}}>
                                                                    <img src={'./assets/imgs/shop/v2_ic_cart.png'} />
                                                                    Grosir<br/> Total Belanja<br/> ≥ Rp 500.000<br/> &nbsp;
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <ul id="ul-categories-end" className="end">
                                                            <li style={{'height': 'unset'}} onClick={() => {this.handleRadioChange('Retail')}}>
                                                                <input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={'Retail'} name="jenisorder_mobile" checked={((this.state.orderType == 'Retail') ? 'checked' : '')} />
                                                                <a style={{'padding': '0px', 'font-size': '9px'}}>
                                                                    <img src={'./assets/imgs/shop/v2_ic_basket.png'} />
                                                                    Retail<br/> Total Belanja<br/> ≥ Rp 300.000<br/> &lt; Rp 500.000
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="section-title" style={{'display': 'none'}}>
                                <div className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow"
                                     id="carausel-10-columns-arrows"></div>
                            </div>
                            <div className="carausel-10-columns-cover position-relative" style={{'display': 'none'}}>
                                <div className="carausel-10-columns" id="carausel-10-columns">
                                    {
                                        this.state.categoryData.map((object, i) => {
                                            return <div className={'card-2 ' + ((i%2 == 1) ? 'bg-9' : 'bg-11') + ' wow animate__animated animate__fadeInUp'}
                                                        data-wow-delay=".1s">
                                                <figure className="img-hover-scale overflow-hidden">
                                                    <a onClick={() => {this.handleCategoriesClick(object.KodeJenisBarang)}}><img src={object.img_url}/></a>
                                                </figure>
                                                <h6 style={{'font-size': '14px'}}>
                                                    <a onClick={() => {this.handleCategoriesClick(object.KodeJenisBarang)}}>{object.JenisBarang}</a>
                                                </h6>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container mb-30">
                        <div className="row">
                            <div className="col-12">
                                <div className="shop-product-fillter" style={{'display': 'none'}}>
                                    <div className="totall-product">
                                        {/*<p>We found <strong className="text-brand">29</strong> items for you!</p>*/}
                                    </div>
                                    <div className="sort-by-product-area">
                                        <div className="sort-by-cover mr-10 mb-5">
                                            <div className="sort-by-product-wrap">
                                                <div className="sort-by">
                                                    <span><i className="fi-rs-building"></i>Gudang:</span>
                                                </div>
                                                <div className="sort-by-dropdown-wrap">
                                                    <span> {this.state.branchName} <i className="fi-rs-angle-small-down"></i></span>
                                                </div>
                                            </div>
                                            <div className="sort-by-dropdown">
                                                <ul>
                                                    {
                                                        this.state.branchData.map((object, i) => {
                                                            return <li><a className={(object.KodeCabang == this.state.branchCode) ? 'active': ''} href="#" onClick={() => {this.handleBranchByClick(object)}}>{object.NamaCabang}</a></li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="sort-by-cover mr-10 mb-5">
                                            <div className="sort-by-product-wrap">
                                                <div className="sort-by">
                                                    <span><i className="fi-rs-apps"></i>Show:</span>
                                                </div>
                                                <div className="sort-by-dropdown-wrap">
                                                    <span> {this.state.perPage} <i className="fi-rs-angle-small-down"></i></span>
                                                </div>
                                            </div>
                                            <div className="sort-by-dropdown">
                                                <ul>
                                                    {
                                                        this.state.pageNumList.map((object, i) => {
                                                            return <li><a className={(object == this.state.perPage) ? 'active': ''} href="#" onClick={() => {this.handlePageNumClick(object)}}>{object}</a></li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="sort-by-cover mr-10 mb-5">
                                            <div className="sort-by-product-wrap">
                                                <div className="sort-by">
                                                    <span><i className="fi-rs-apps-sort"></i>Sort by:</span>
                                                </div>
                                                <div className="sort-by-dropdown-wrap">
                                                    <span> Featured <i className="fi-rs-angle-small-down"></i></span>
                                                </div>
                                            </div>
                                            <div className="sort-by-dropdown">
                                                <ul>
                                                    {
                                                        this.state.sortData.map((object, i) => {
                                                            return <li><a className={(object == this.state.sortDataValue) ? 'active': ''} href="#" onClick={() => {this.handleSortByClick(object)}}>{object}</a></li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*{*/}
                                    {/*this.state.sessFullname && (*/}
                                        {/*<div id={'reorderEl'} className="row product-grid" style={{'padding-top': '15px'}}>*/}
                                            {/*<div className="mb-15" style={{'display': 'flex'}}>*/}
                                                {/*<h2 style={{'font-size': '15px', 'flex': '1'}}>Hi {this.state.sessFullname}, beli lagi yuk</h2>*/}
                                                {/*<h2 style={{'font-size': '13px', 'color': '#F15D30'}}><a>Lihat Semua</a></h2>*/}
                                            {/*</div>*/}
                                            {/*<div style={{'background-color': '#FFDEA3', 'background-size': '100%'}}>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*)*/}
                                {/*}*/}
                                <div id={'productEl'} className="row product-grid" style={{'background-color': '#F5F6F6', 'background-size': '100%', 'padding-top': '15px'}}>
                                    <div className="mb-15" style={{'display': 'flex'}}>
                                        <h2 style={{'font-size': '15px', 'flex': '1'}}>Terlaris Minggu ini</h2>
                                        <h2 style={{'font-size': '13px', 'color': '#F15D30'}}><a href={'./shop'}>Lihat Semua</a></h2>
                                    </div>
                                    {
                                        this.state.postData && (
                                            this.state.postData
                                        )
                                    }
                                    {
                                        !this.state.postData && (
                                            this.state.postDataSkeleton
                                        )
                                    }
                                </div>
                                <div className="pagination-area mt-10 mb-40" style={{'display': 'none'}}>
                                    <nav aria-label="Page navigation example">
                                        <ReactPaginate
                                            previousLabel={<i className="fi-rs-arrow-small-left"></i>}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextLabel={<i className="fi-rs-arrow-small-right"></i>}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakLabel={"..."}
                                            breakClassName={"page-link dot"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-start"}
                                            activeClassName={"page-item active"}
                                            activeLinkClassName={"page-link"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}/>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default Home;