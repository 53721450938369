import React from "react";

import { Helpers } from "../../config/Helpers"
import {ReactSession} from "react-client-session";
import {toast} from "react-toastify";
import {encode as base64_encode} from "base-64";

class WithdrawalCommission extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessKodeNasabah: (ReactSession.get("sess_kodenasabah")) ? ReactSession.get("sess_kodenasabah") : '',
            sessUserType: (ReactSession.get("sess_usertype")) ? ReactSession.get("sess_usertype") : '',
            summaryData: '',
            jumlah: '',
            diterima: '0'
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getSummary();
        }
    }

    getSummary = () => {
        Helpers.getSummary(this.state.sessUserCode, this.state.sessKodeNasabah).then(res => {
            this.setState({
                summaryData: res[0]
            });
        })
    };

    handleOnChange = (e) => {
        const withdraw = parseInt(e.target.value);
        const biaya_transfer = parseInt(this.state.summaryData.biaya_transfer);
        const receive = withdraw-biaya_transfer;

        this.setState({
            jumlah: e.target.value,
            diterima: receive
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const saldo = parseInt(this.state.summaryData.commission_now_raw);
        const min_transfer = parseInt(this.state.summaryData.minimal_transfer);
        const diterima = parseInt(this.state.diterima);
        const after_fee = parseInt(this.state.summaryData.minimal_transfer_after_fee);

        if(diterima<after_fee){
            toast.error("Nominal penarikan tidak mencukupi");
        }else if(saldo<min_transfer){
            toast.error("Saldo penarikan tidak mencukupi");
        }else{
            if (window.confirm('Apakah anda yakin akan menghapus alamat ini?')) {
                Helpers.saveWithdrawal(
                    this.state.sessUserType,
                    this.state.sessUserCode,
                    this.state.diterima,
                    '',
                    '',
                    '',
                    this.state.sessEmail
                ).then(res => {
                    if(res.length > 0){
                        if(res[0].alert == 'success'){
                            this.setState({
                                jumlah: '',
                                diterima: '0'
                            });

                            this.getSummary();
                        }

                        toast[res[0].alert](res[0].message);
                    }else{
                        toast.error(Helpers.MSG_ERROR);
                    }
                })
            } else {
                console.log('Cancel.');
            }
        }
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Akun <span></span> Penarikan Komisi
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-10">

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 m-auto">
                                    <div className="primary-sidebar">
                                        <div className="sidebar-widget widget-store-info mb-30 bg-2 border-0 info-custom">
                                            <div className="vendor-info">
                                                <h4 className="mb-5">
                                                    <a href="#" className="text-heading">
                                                        Syarat & Ketentuan</a>
                                                </h4>

                                                <div className="product-rate-cover mb-15">
                                                </div>

                                                <div className="vendor-des">
                                                    <p className="font-sm text-heading">
                                                        <ol style={{'list-style': 'list-number'}}>
                                                            <li>Minimal penarikan adalah Rp <strong>{parseInt(this.state.summaryData.minimal_transfer).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</strong></li>
                                                            <li>Pastikan data yang anda masukkan sudah sesuai</li>
                                                            <li>Penarikan akan ditransfer ke no rekening yang anda daftarkan di profil dan dikenakan biaya admin sebesar Rp <strong>{( (this.state.summaryData.biaya_transfer == '6500') ? '2.000 - 6.500' : parseInt(this.state.summaryData.biaya_transfer).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.'))}</strong></li>
                                                            <li>Biaya admin akan dipotong dari total penarikan</li>
                                                            <li>Dana akan anda terima maksimal dalam 60 menit</li>
                                                            <li>Setelah data dikirim, permintaan tidak dapat dibatalkan.</li>
                                                        </ol>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <form className="contact-form-style mt-30 mb-30"
                                          method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                        <div className="input-style mb-20">
                                            <strong>Saldo Komisi</strong>
                                            <input value={this.state.summaryData.commission_now}
                                                   placeholder="Saldo Komisi"
                                                   type="text" readOnly={true}/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <strong>Jumlah Transfer</strong>
                                            <input value={this.state.jumlah} onChange={e => this.handleOnChange(e)}
                                                   min={0}
                                                   max={parseInt(this.state.summaryData.commission_now_raw)}
                                                   placeholder="Masukkan Jumlah Transfer"
                                                   type="number" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <strong>Jumlah Diterima</strong>
                                            <input value={this.state.diterima}
                                                   placeholder="Jumlah Diterima"
                                                   type="number" readOnly={true} required/>
                                        </div>
                                        <button className="submit submit-auto-width"
                                                type="submit">Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </>
        );
    }
}

export default WithdrawalCommission;