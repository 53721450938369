import React from "react";
import {ReactSession} from "react-client-session";

import { Helpers } from "../config/Helpers";
import {toast} from "react-toastify";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import queryString from "query-string";

class Checkout extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessKodeNasabah: (ReactSession.get("sess_kodenasabah")) ? ReactSession.get("sess_kodenasabah") : '',
            summaryData: '',
            paymentData: [],
            warungData: [],
            orderData: [],
            orderTotal: 0,
            orderGrandTotal: 0,
            orderTotalKomisi: 0,
            orderTotalBerat: 0,
            orderKomisiDigunakan: 0,
            orderBBXPoinDigunakan: 0,
            orderDeliveryType: (ReactSession.get("sess_order_delivery")) ? ReactSession.get("sess_order_delivery") : 'Ambil Sendiri',
            orderDeliveryLocation: (ReactSession.get("sess_order_kodelokasi")) ? ReactSession.get("sess_order_kodelokasi") : '',
            orderPaymentMethod: '',
            orderPaymentMethodType: '',
            orderPaymentCharge: 0,
            orderPaymentFee: '',
            orderWarung: '',
            orderType: (ReactSession.get("order_type")) ? ReactSession.get("order_type") : 'Grosir',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            orderLocPinPoint: (ReactSession.get("sess_order_pinpoint")) ? ReactSession.get("sess_order_pinpoint") : '',
            orderLocJarak: (ReactSession.get("sess_order_jarak")) ? ReactSession.get("sess_order_jarak") : '0',
            orderLocJasaKirim: (ReactSession.get("sess_order_jasakirim")) ? ReactSession.get("sess_order_jasakirim") : '0',
            isCommissionChecked: false,
            saldoCommission: 0,
            typeCommission: 'Pilih',
            isBBXPoinChecked: false,
            saldoBBXPoin: 0,
            urlParam: queryString.parse(window.location.search),
            kodeMarketPlaceDetail: '',
            orderShipmentMethod: '',
            koinWorksTenor: '30',
            id_store_bbx: '',
            password_waroq: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getSummary();
            this.getWarung();
            this.showCart();
            this.handleRadioChange(this.state.orderDeliveryType);
        }
    }

    getSummary = () => {
        Helpers.getSummary(this.state.sessUserCode, this.state.sessKodeNasabah).then(res => {
            this.setState({
                summaryData: res[0],
                saldoCommission: res[0].commission_now_raw,
                saldoBBXPoin: res[0].bbx_poin,
                id_store_bbx: res[0].id_store_bbx,
                kodeMarketPlaceDetail: (this.state.urlParam.joinid) ? base64_decode(this.state.urlParam.joinid) : '',
            }, () => {
                this.callPaymentMethod();

                if(this.state.orderDeliveryLocation && this.state.orderDeliveryType == 'Biteship'){
                    this.callShipmentMethod();
                }
            });
        })
    };

    getWarung = () => {
        Helpers.getWarung('','').then(res => {
            this.setState({
                warungData: res
            });
        })
    };

    callPaymentMethod = () => {
        Helpers.getListPaymentChannel('').then(res => {
            const generatePaymentMethod = res.map((object, i) => {
                return <tr>
                    <td>
                        <input type="radio" style={{'height': '11px', width: '11px'}} value={object.KodePaymentGateway} name="paymentmethod" checked={((this.state.orderPaymentMethod == object.KodePaymentGateway) ? 'checked' : '')} onChange={() => {this.handleRadioPaymentChange(object)}}/>
                    </td>
                    <td className="image product-thumbnail"><img
                        src={object.Logo} alt="#"/></td>
                    <td>
                        <h6 className="text-brand text-align-left" style={{'vertical-align': 'unset'}}>{object.Shortname}</h6>
                        <h6 className="w-160 mb-5 text-align-left">
                            {
                                object.KodePaymentGateway == 'Limit' && (
                                    <a href="#" className="text-heading">Saldo: Rp {this.state.summaryData.credit_limit}</a>
                                )
                            }
                            {
                                object.KodePaymentGateway == 'Saldo' && (
                                    <a href="#" className="text-heading">Saldo: Rp {this.state.summaryData.saldo_waroq}</a>
                                )
                            }
                            {
                                object.KodePaymentGateway == 'KPG-0017' && (
                                    <a href="#" className="text-heading">Saldo: Rp {this.state.summaryData.KoinWorksLimitFormat}</a>
                                )
                            }
                            {
                                (object.KodePaymentGateway !== 'Limit' && object.KodePaymentGateway !== 'Saldo' && object.KodePaymentGateway !== 'KPG-0017') && (
                                    <a href="#" className="text-heading">{object.Fullname}</a>
                                )
                            }
                        </h6>
                    </td>
                </tr>
            });

            this.setState({
                paymentData: res,
                generatePaymentMethod
            });
        })
    };

    callShipmentMethod = () => {
        Helpers.getBiteshipRates(
            this.state.sessEmail,
            this.state.branchCode,
            this.state.orderDeliveryLocation,
            this.state.orderType
        ).then(res => {
            if(res.length > 0){
                const generateShipmentMethod = res.map((object, i) => {
                    let shipmentCode = object.company + '|' + object.type;

                    return <tr>
                        <td>
                            <input type="radio" style={{'height': '11px', width: '11px'}} value={shipmentCode} name="shipmentmethod" checked={((this.state.orderShipmentMethod == shipmentCode) ? 'checked' : '')} onChange={() => {this.handleRadioShipmentChange(object)}}/>
                        </td>
                        <td>
                            <h6 className="text-brand text-align-left" style={{'vertical-align': 'unset'}}>{object.courier_name} ({object.duration})</h6>
                            <h6 className="w-160 mb-5 text-align-left">
                                <a href="#" className="text-heading">{object.courier_service_name}: Rp {parseFloat(object.price).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</a>
                            </h6>
                        </td>
                    </tr>
                });

                this.setState({
                    generateShipmentMethod
                });
            }
        })
    };

    calculateTotal = () => {
        let jasa_kirim = (parseInt(this.state.orderTotal) > 1000000) ? 0 : parseInt(this.state.orderLocJasaKirim);
        let check_total_komisi = parseInt(this.state.summaryData.commission_now_raw);
        let check_total_bbxpoin = parseInt(this.state.summaryData.bbx_poin);
        let total_harga_kj = parseInt(this.state.orderTotal) + jasa_kirim;
        let sisa_komisi = check_total_komisi;
        let sisa_bbxpoin = check_total_bbxpoin;
        let payment_fee = 0;
        let jenis_komisi = '';
        let MetodePembayaran = '';
        let IDPayment = '';
        let komisi_digunakan = 0;
        let bbxpoin_digunakan = 0;
        let sum_total_payment = 0;

        if(this.state.isCommissionChecked || this.state.isBBXPoinChecked){
            if(this.state.isCommissionChecked && !this.state.isBBXPoinChecked){
                if(check_total_komisi>=total_harga_kj) {
                    MetodePembayaran = "Saldo";
                    IDPayment = "Saldo";

                    sisa_komisi = check_total_komisi - total_harga_kj;
                    komisi_digunakan = total_harga_kj;
                    payment_fee = 0;

                    this.callPaymentMethod();
                }else if(check_total_komisi<total_harga_kj){
                    if(this.state.orderPaymentFee == ''){
                        MetodePembayaran = "";
                        IDPayment = "";
                        sisa_komisi = 0;
                        komisi_digunakan = check_total_komisi;
                        payment_fee = 0;
                    }else{
                        MetodePembayaran = this.state.orderPaymentMethodType;
                        IDPayment = this.state.orderPaymentMethod;

                        sisa_komisi = 0;
                        komisi_digunakan = check_total_komisi;

                        sum_total_payment = total_harga_kj - komisi_digunakan;
                        if(this.state.orderPaymentMethodType == 'e-Money'){
                            payment_fee = (parseFloat(this.state.orderPaymentFee)/100) * parseInt(sum_total_payment);
                        }else if(this.state.orderPaymentMethodType == 'Credit Card'){
                            payment_fee = ((parseFloat(this.state.orderPaymentFee)/100) * (parseInt(sum_total_payment)+5000)) + 6680;
                        }else{
                            payment_fee = parseInt(this.state.orderPaymentFee);
                        }
                    }
                }
            }else if(!this.state.isCommissionChecked && this.state.isBBXPoinChecked){
                if(check_total_bbxpoin>=total_harga_kj) {
                    MetodePembayaran = "Saldo";
                    IDPayment = "Saldo";

                    sisa_bbxpoin = check_total_bbxpoin - total_harga_kj;
                    bbxpoin_digunakan = total_harga_kj;
                    payment_fee = 0;

                    this.callPaymentMethod();
                }else if(check_total_bbxpoin<total_harga_kj){
                    if(this.state.orderPaymentFee == ''){
                        MetodePembayaran = "";
                        IDPayment = "";
                        sisa_bbxpoin = 0;
                        bbxpoin_digunakan = check_total_bbxpoin;
                        payment_fee = 0;
                    }else{
                        MetodePembayaran = this.state.orderPaymentMethodType;
                        IDPayment = this.state.orderPaymentMethod;

                        sisa_bbxpoin = 0;
                        bbxpoin_digunakan = check_total_bbxpoin;

                        sum_total_payment = total_harga_kj - bbxpoin_digunakan;
                        if(this.state.orderPaymentMethodType == 'e-Money'){
                            payment_fee = (parseFloat(this.state.orderPaymentFee)/100) * parseInt(sum_total_payment);
                        }else if(this.state.orderPaymentMethodType == 'Credit Card'){
                            payment_fee = ((parseFloat(this.state.orderPaymentFee)/100) * (parseInt(sum_total_payment)+5000)) + 6680;
                        }else{
                            payment_fee = parseInt(this.state.orderPaymentFee);
                        }
                    }
                }
            }else{
                if(check_total_komisi>=total_harga_kj) {
                    MetodePembayaran = "Saldo";
                    IDPayment = "Saldo";

                    sisa_komisi = check_total_komisi - total_harga_kj;
                    komisi_digunakan = total_harga_kj;
                    payment_fee = 0;

                    if(this.state.isBBXPoinChecked){
                        this.setState({
                            isBBXPoinChecked: !this.state.isBBXPoinChecked
                        });

                        sisa_bbxpoin = check_total_bbxpoin;
                        bbxpoin_digunakan = 0;
                    }

                    this.callPaymentMethod();
                }else if(check_total_komisi<total_harga_kj){
                    if(this.state.orderPaymentFee == ''){
                        MetodePembayaran = "";
                        IDPayment = "";
                        sisa_komisi = 0;
                        komisi_digunakan = check_total_komisi;
                        payment_fee = 0;

                        if(check_total_bbxpoin>=(total_harga_kj-komisi_digunakan)) {
                            MetodePembayaran = "Saldo";
                            IDPayment = "Saldo";

                            sisa_bbxpoin = check_total_bbxpoin - (total_harga_kj-komisi_digunakan);
                            bbxpoin_digunakan = (total_harga_kj-komisi_digunakan);
                            payment_fee = 0;

                            this.callPaymentMethod();
                        }else if(check_total_bbxpoin<(total_harga_kj-komisi_digunakan)){
                            if(this.state.orderPaymentFee == ''){
                                MetodePembayaran = "";
                                IDPayment = "";
                                sisa_bbxpoin = 0;
                                bbxpoin_digunakan = check_total_bbxpoin;
                                payment_fee = 0;
                            }else{
                                MetodePembayaran = this.state.orderPaymentMethodType;
                                IDPayment = this.state.orderPaymentMethod;

                                sisa_bbxpoin = 0;
                                bbxpoin_digunakan = check_total_bbxpoin;

                                sum_total_payment = total_harga_kj - bbxpoin_digunakan- komisi_digunakan;
                                if(this.state.orderPaymentMethodType == 'e-Money'){
                                    payment_fee = (parseFloat(this.state.orderPaymentFee)/100) * parseInt(sum_total_payment);
                                }else if(this.state.orderPaymentMethodType == 'Credit Card'){
                                    payment_fee = ((parseFloat(this.state.orderPaymentFee)/100) * (parseInt(sum_total_payment)+5000)) + 6680;
                                }else{
                                    payment_fee = parseInt(this.state.orderPaymentFee);
                                }
                            }
                        }
                    }else{
                        sisa_komisi = 0;
                        komisi_digunakan = check_total_komisi;

                        if(check_total_bbxpoin>=(total_harga_kj-komisi_digunakan)) {
                            MetodePembayaran = "Saldo";
                            IDPayment = "Saldo";

                            sisa_bbxpoin = check_total_bbxpoin - (total_harga_kj-komisi_digunakan);
                            bbxpoin_digunakan = (total_harga_kj-komisi_digunakan);
                            payment_fee = 0;

                            this.callPaymentMethod();
                        }else if(check_total_bbxpoin<(total_harga_kj-komisi_digunakan)){
                            if(this.state.orderPaymentFee == ''){
                                MetodePembayaran = "";
                                IDPayment = "";
                                sisa_bbxpoin = 0;
                                bbxpoin_digunakan = check_total_bbxpoin;
                                payment_fee = 0;
                            }else{
                                MetodePembayaran = this.state.orderPaymentMethodType;
                                IDPayment = this.state.orderPaymentMethod;

                                sisa_bbxpoin = 0;
                                bbxpoin_digunakan = check_total_bbxpoin;

                                sum_total_payment = total_harga_kj - bbxpoin_digunakan- komisi_digunakan;
                                if(this.state.orderPaymentMethodType == 'e-Money'){
                                    payment_fee = (parseFloat(this.state.orderPaymentFee)/100) * parseInt(sum_total_payment);
                                }else if(this.state.orderPaymentMethodType == 'Credit Card'){
                                    payment_fee = ((parseFloat(this.state.orderPaymentFee)/100) * (parseInt(sum_total_payment)+5000)) + 6680;
                                }else{
                                    payment_fee = parseInt(this.state.orderPaymentFee);
                                }
                            }
                        }
                    }
                }
            }
        }else{
            jenis_komisi = "Pilih";

            if(this.state.orderPaymentFee === ''){
                MetodePembayaran = "";
                IDPayment = "";
                sisa_komisi = check_total_komisi;
                sisa_bbxpoin = check_total_bbxpoin;
                komisi_digunakan = 0;
                bbxpoin_digunakan = 0;
                payment_fee = 0;
            }else{
                MetodePembayaran = this.state.orderPaymentMethodType;
                IDPayment = this.state.orderPaymentMethod;

                sisa_komisi = check_total_komisi;
                sisa_bbxpoin = check_total_bbxpoin;
                komisi_digunakan = 0;
                bbxpoin_digunakan = 0;

                sum_total_payment = total_harga_kj - komisi_digunakan - bbxpoin_digunakan;
                if(this.state.orderPaymentMethodType == 'e-Money'){
                    payment_fee = (parseFloat(this.state.orderPaymentFee)/100) * parseInt(sum_total_payment);
                }else if(this.state.orderPaymentMethodType == 'Credit Card'){
                    payment_fee = ((parseFloat(this.state.orderPaymentFee)/100) * (parseInt(sum_total_payment)+5000)) + 6680;
                }else{
                    payment_fee = parseInt(this.state.orderPaymentFee);
                }
            }
        }

        let total_harga_by_flow = total_harga_kj + payment_fee - komisi_digunakan - bbxpoin_digunakan;

        this.setState({
            typeCommission: jenis_komisi,
            orderPaymentMethodType: MetodePembayaran,
            orderPaymentMethod: IDPayment,
            orderGrandTotal: total_harga_by_flow,
            orderPaymentCharge: payment_fee,
            orderKomisiDigunakan: komisi_digunakan,
            saldoCommission: sisa_komisi,
            orderBBXPoinDigunakan: bbxpoin_digunakan,
            saldoBBXPoin: sisa_bbxpoin,
            orderLocJasaKirim: jasa_kirim
        });
    };

    showCart = () => {
        $("#preloader-active-x").css("display", "");
        Helpers.getProductDetails(this.state.sessEmail, '', this.state.branchCode, 'yes').then(res => {
            this.setState({
                orderData: res.filter(item => {
                    const qtyOrder = parseInt(item.QtyOrder);

                    return qtyOrder > 0;
                })
            }, () => {
                this.setState({
                    orderTotal: res.filter(item => {
                        const qtyOrder = parseInt(item.QtyOrder);

                        return qtyOrder > 0;
                    }).reduce((a,v) =>  a = a + ((this.state.orderType == 'Grosir') ? v.TotalHargaGrosirTerkecil : v.TotalHargaRetailTerkecil) , 0 ),
                    orderGrandTotal: res.filter(item => {
                        const qtyOrder = parseInt(item.QtyOrder);

                        return qtyOrder > 0;
                    }).reduce((a,v) =>  a = a + ((this.state.orderType == 'Grosir') ? v.TotalHargaGrosirTerkecil : v.TotalHargaRetailTerkecil) , 0 ),
                    orderTotalKomisi: res.filter(item => {
                        const qtyOrder = parseInt(item.QtyOrder);

                        return qtyOrder > 0;
                    }).reduce((a,v) =>  a = a + ((this.state.orderType == 'Grosir') ? parseInt(v.KomisiGrosirFormat) : parseInt(v.KomisiRetailFormat)) , 0 ).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.'),
                    orderTotalBerat: res.filter(item => {
                        const qtyOrder = parseInt(item.QtyOrder);

                        return qtyOrder > 0;
                    }).reduce((a,v) =>  a = a + v.TotalBerat , 0 ).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')
                }, () => {
                    this.calculateTotal();
                    $("#preloader-active-x").css("display", "none");
                })
            });
        })
    };

    handleSubmitOrderTemp = (detailId, qtyUpdate) => {
        Helpers.createOrderTemp(this.state.sessEmail, detailId, qtyUpdate, this.state.branchCode, 'yes').then(res => {
            if(res[0].alert == 'success'){
                this.showCart();

                ReactSession.set("update_dropdown_cart", "t");
            }

            toast[res[0].alert](res[0].message);
        })
    };

    handleRadioChange = (object) => {
        if(object == 'Ambil Sendiri'){
            this.setState({
                orderDeliveryType: object,
                orderDeliveryLocation: '',
                orderLocPinPoint: '',
                orderLocJarak: '0',
                orderLocJasaKirim: '0',
            }, () => {
                ReactSession.set("sess_order_delivery", object);

                this.calculateTotal();
            });
        }else if(object == 'Jasa Kurir' || object == 'Biteship'){
            this.setState({
                orderDeliveryType: object,
                orderDeliveryLocation: (ReactSession.get("sess_order_kodelokasi")) ? ReactSession.get("sess_order_kodelokasi") : '',
                orderLocPinPoint: (ReactSession.get("sess_order_pinpoint")) ? ReactSession.get("sess_order_pinpoint") : '',
                orderLocJarak: (ReactSession.get("sess_order_jarak")) ? ReactSession.get("sess_order_jarak") : '0',
                orderLocJasaKirim: (ReactSession.get("sess_order_jasakirim")) ? ReactSession.get("sess_order_jasakirim") : '0',
            }, () => {
                ReactSession.set("sess_order_delivery", object);

                if(object == 'Biteship'){
                    this.setState({
                        orderLocJasaKirim: '0',
                    }, () => {
                        if(this.state.orderDeliveryLocation && this.state.orderDeliveryType == 'Biteship'){
                            this.callShipmentMethod();
                            this.calculateTotal();
                        }
                    });
                }else{
                    this.calculateTotal();
                }
            });
        }
    };

    handleRadioPaymentChange = (object) => {
        this.setState({
            orderPaymentMethod: object.KodePaymentGateway,
            orderPaymentFee: object.Fee,
            orderPaymentMethodType: object.JenisBank
        }, () => {
            this.callPaymentMethod();
            this.calculateTotal();
        });

        if(object.KodePaymentGateway === 'KPG-0017'){
            $("#koinworks-tenor").css("display", "");
        }else{
            $("#koinworks-tenor").css("display", "none");
        }
    };

    handleRadioShipmentChange = (object) => {
        this.setState({
            orderShipmentMethod: object.company + '|' + object.type,
            orderLocJasaKirim: object.price
        }, () => {
            if(this.state.orderDeliveryLocation && this.state.orderDeliveryType == 'Biteship'){
                this.callShipmentMethod();
                this.calculateTotal();
            }
        });
    };

    handleCommissionChecked = () => {
        this.setState({
            isCommissionChecked: !this.state.isCommissionChecked
        }, () => {
            this.calculateTotal();
        });
    };

    handleBBXPoinChecked = () => {
        this.setState({
            isBBXPoinChecked: !this.state.isBBXPoinChecked
        }, () => {
            this.calculateTotal();
        });
    };

    handleUpload = () => {
        if(this.state.kodeMarketPlaceDetail !== ''){
            this.handleCheckPayment();
        }else{
            if(this.state.orderType == 'Grosir'){
                if(parseInt(this.state.orderTotal) >= 500000){
                    this.handleCheckPayment();
                }else{
                    ReactSession.set("order_type", "Retail");

                    toast.error("Total order grosir minimal 500.000, jenis order akan otomatis diubah ke retail.");

                    setTimeout(function() {
                        window.location.reload();
                    }, 1000);
                }
            }else if(this.state.orderType == 'Retail'){
                this.handleCheckPayment();
            }else{
                toast.error("Nominal belanja belum dipilih");
            }
        }
    };

    handleCheckPayment = () => {
        if(this.state.orderPaymentMethodType == 'Limit'){
            if(parseInt(this.state.orderGrandTotal) <= parseInt(this.state.summaryData.credit_limit_raw)){
                this.handleSendUpload();
            }else{
                toast.error("Total order di keranjang tidak boleh melebihi limit yang tersedia");
            }
        }else if(this.state.orderPaymentMethodType == 'Saldo'){
            if(parseInt(this.state.orderGrandTotal) <= parseInt(this.state.summaryData.saldo_waroq_raw)){
                this.handleSendUpload();
            }else{
                toast.error("Total order di keranjang tidak boleh melebihi saldo yang tersedia");
            }
        }else if(this.state.orderPaymentMethodType == 'Bank'){
            this.handleSendUpload();
        }else if(this.state.orderPaymentMethodType == 'e-Money'){
            this.handleSendUpload();
        }else if(this.state.orderPaymentMethodType == 'Credit Card'){
            this.handleSendUpload();
        }else if(this.state.orderPaymentMethodType == 'Fintech'){
            this.handleSendUpload();
        }else{
            toast.error("Metode pembayaran belum dipilih");
        }
    };

    handleSendUpload = () => {
        if(this.state.orderDeliveryType == ''){
            toast.error("Lokasi atau jenis pengiriman belum dipilih");
        }else{
            if(this.state.orderDeliveryType == 'Jasa Kurir' && this.state.orderDeliveryLocation == ''){
                toast.error("Lokasi atau jenis pengiriman belum dipilih");
            }else{
                this.handlePrepareUpload();
            }
        }
    };

    handlePrepareUpload = () => {
        if(parseInt(this.state.orderTotal) > 0){
            if (window.confirm('Apakah order anda sudah sesuai? Klik ok untuk melanjutkan')) {
                if(this.state.isBBXPoinChecked){
                    if(ReactSession.get("sess_password") === this.state.password_waroq){
                        this.submitOrderSend();
                    }else{
                        toast.error("Password tida sesuai");
                    }
                }else{
                    this.submitOrderSend();
                }
            }else{
                console.log('Cancel.');
            }
        }else{
            toast.error("Anda belum memiliki item di keranjang");
        }
    };

    submitOrderSend = () => {
        Helpers.createOrder(
            (this.state.orderWarung) ? this.state.orderWarung : this.state.sessUserCode,
            this.state.sessEmail,
            '',
            this.state.orderKomisiDigunakan,
            this.state.typeCommission,
            this.state.orderDeliveryType,
            this.state.orderDeliveryLocation,
            this.state.orderPaymentMethodType,
            this.state.orderType,
            this.state.orderPaymentMethod,
            this.state.kodeMarketPlaceDetail,
            this.state.branchCode,
            this.state.orderShipmentMethod,
            this.state.orderLocJasaKirim,
            this.state.koinWorksTenor,
            'yes',
            this.state.orderBBXPoinDigunakan,
            this.state.sessKodeNasabah,
            this.state.id_store_bbx,
            "Bayar order"
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    setTimeout(function() {
                        window.location.href = './account-orders-details?trxid=' + base64_encode(res[0].KodeTransaksiOrder);
                    }, 1000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <main className="main">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Keranjang
                            </div>
                        </div>
                    </div>
                    <div className="container mb-80 mt-15">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="cart-action justify-content-between mb-15">
                                    <a className="btn mr-10" style={{'padding': '12px 10px'}} href={(this.state.kodeMarketPlaceDetail) ? './shop?joinid=' + base64_encode(this.state.kodeMarketPlaceDetail) : './shop'}><i className="fi-rs-arrow-left mr-10"></i>Lanjut Belanja</a>
                                    <a className="btn btn-secondary" style={{'padding': '12px 10px'}} onClick={() => {this.handleSubmitOrderTemp('delete_all', 0)}}><i className="fi-rs-trash mr-10"></i>Hapus Semua</a>
                                </div>
                                <div className="table-responsive shopping-summery">
                                    <table className="table table-wishlist">
                                        <thead>
                                        <tr className="main-heading">
                                            <th scope="col" colSpan="2" className="column-dekstop start pl-30">Product</th>
                                            <th scope="col" className="column-mobile start pl-30">Product</th>
                                            <th scope="col" style={{'text-align': 'center'}}>Unit Price</th>
                                            <th scope="col" style={{'text-align': 'center'}}>Qty</th>
                                            <th scope="col" style={{'text-align': 'center'}}>Subtotal</th>
                                            <th scope="col" className="end"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.orderData.map((object, i) => {
                                                return <tr className="pt-30">
                                                    <td className="image product-thumbnail pt-40 column-dekstop"><img
                                                        src={object.img_url} alt="#" />
                                                    </td>
                                                    <td className="product-des product-name">
                                                        <h6 className="mb-5">
                                                            <a className="product-name mb-10 text-heading" href="#">{object.NamaBarang}</a>
                                                        </h6>
                                                        <div className="product-rate-cover">
                                                            <span className="font-small text-muted">Unit: {object.UnitBarang}</span> <br/>
                                                            <span className="font-small text-muted">Berat: {object.TotalBerat} gr</span>
                                                        </div>
                                                        {
                                                            this.state.orderType == 'Grosir' && (
                                                                <span className="font-small text-muted">Est. Komisi: {object.KomisiGrosirFormat}</span>
                                                            )
                                                        }
                                                        {
                                                            this.state.orderType == 'Retail' && (
                                                                <span className="font-small text-muted">Est. Komisi: {object.KomisiRetailFormat}</span>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="price" align="right" style={{'padding-right': '5px'}}>
                                                        {
                                                            this.state.orderType == 'Grosir' && (
                                                                <h4 className="text-body" style={{'font-size': '17px'}}>{object.HargaGrosirFormatTerkecil}</h4>
                                                            )
                                                        }
                                                        {
                                                            this.state.orderType == 'Retail' && (
                                                                <h4 className="text-body" style={{'font-size': '17px'}}>{object.HargaRetailFormatTerkecil}</h4>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="text-center detail-info">
                                                        <div className="detail-extralink mr-15">
                                                            <div className="detail-qty border radius">
                                                                <a className="qty-down" onClick={() => {this.handleSubmitOrderTemp(object.DetailId, object.QtyOrder-1)}}><i
                                                                    className="fi-rs-angle-small-down"></i></a>
                                                                <span className="qty-val">{object.QtyOrder}</span>
                                                                <a className="qty-up" onClick={() => {this.handleSubmitOrderTemp(object.DetailId, object.QtyOrder+1)}}><i
                                                                    className="fi-rs-angle-small-up"></i></a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="price" align="right" style={{'padding-right': '5px'}}>
                                                        {
                                                            this.state.orderType == 'Grosir' && (
                                                                <h4 className="text-brand" style={{'font-size': '17px'}}>{object.TotalHargaGrosirFormatTerkecil}</h4>
                                                            )
                                                        }
                                                        {
                                                            this.state.orderType == 'Retail' && (
                                                                <h4 className="text-brand" style={{'font-size': '17px'}}>{object.TotalHargaRetailFormatTerkecil}</h4>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="action text-center">
                                                        <a className="text-body" onClick={() => {this.handleSubmitOrderTemp(object.DetailId, 0)}}><i className="fi-rs-trash"></i></a>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="divider-2 mb-30"></div>
                            </div>
                            <div className="col-lg-4">
                                <div className="border p-md-4 cart-totals ml-30 mb-15">
                                    <div className="table-responsive">
                                        <table className="table no-border" style={{'margin-bottom': '0px'}}>
                                            <tbody>
                                            <tr>
                                                <td className="cart_total_label" colSpan={3}>
                                                    <h6 className="text-muted">Pengambilan / Pengiriman</h6>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={'pickup'} name="delivtype" checked={((this.state.orderDeliveryType == 'Ambil Sendiri') ? 'checked' : '')} onChange={() => {this.handleRadioChange('Ambil Sendiri')}}/>
                                                </td>
                                                <td className="image product-thumbnail">
                                                    <img src={'./assets/imgs/shop/v2_ic_box.png'} alt="#" style={{'width': '48px'}}/></td>
                                                <td>
                                                    <h6 className="w-160 mb-5 text-brand text-align-left">Ambil Sendiri</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={'delivery'} name="delivtype" checked={((this.state.orderDeliveryType == 'Jasa Kurir') ? 'checked' : '')} onChange={() => {this.handleRadioChange('Jasa Kurir')}}/>
                                                </td>
                                                <td className="image product-thumbnail">
                                                    <img src={'./assets/imgs/shop/v2_ic_truck.png'} alt="#" style={{'width': '48px'}}/></td>
                                                <td>
                                                    <h6 className="w-160 mb-5 text-brand text-align-left">WAROQ Delivery</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={'delivery'} name="delivtype" checked={((this.state.orderDeliveryType == 'Biteship') ? 'checked' : '')} onChange={() => {this.handleRadioChange('Biteship')}}/>
                                                </td>
                                                <td className="image product-thumbnail">
                                                    <img src={'./assets/imgs/shop/v2_ic_truck.png'} alt="#" style={{'width': '48px'}}/></td>
                                                <td>
                                                    <h6 className="w-160 mb-5 text-brand text-align-left">Kurir Pengiriman</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="col" colSpan={3}>
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr>
                                            {
                                                this.state.orderDeliveryType == 'Ambil Sendiri' && (
                                                    <tr>
                                                        <td className="cart_total_label" colSpan={3}>
                                                            <h6 className="mb-10">Alamat Pengambilan</h6>
                                                            <p align="justify">
                                                                {
                                                                    this.state.branchCode=='CBNG-18-020001' && (
                                                                        <>
                                                                            Mayapada Tower 2<br/>
                                                                            (Gudang Waroq/Gudang BBX Mart)<br/>
                                                                            Jl. Jend. Sudirman No.Kav. 27, RT.4/RW.2, Kuningan, Karet, Kecamatan Setiabudi, Kota Jakarta Selatan, DKI Jakarta 12920<br/><br/>
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    this.state.branchCode=='CBNG-18-020002' && (
                                                                        <>
                                                                            RUKO MAYAPADA<br/>
                                                                            Jl. Wibawa Mukti II RT.006/RW.001, Jatiluhur, Kec. Jatiasih (Ruko mayapada), KOTA BEKASI, JATIASIH, JAWA BARAT, ID, 17425<br/><br/>
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    this.state.branchCode=='CBNG-18-020003' && (
                                                                        <>
                                                                            Mayapada Hospital Surabaya (MHSB)<br/>
                                                                            Jl. Mayjen Sungkono No.20, Pakis, Kec. Sawahan, Kota SBY, Jawa Timur 60256<br/><br/>
                                                                        </>
                                                                    )
                                                                }
                                                                <b>Jam operasional gudang untuk pick up</b>:<br/>
                                                                - Senin-Jumat: <b>Jam 08.00 sd 18.00</b><br/>
                                                                - Sabtu: <b>Jam 10.00 sd 17.00</b><br/>
                                                                - Minggu: <font color='#F60000'><b>Tidak beroperasi</b></font>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                (this.state.orderDeliveryType == 'Jasa Kurir' || this.state.orderDeliveryType == 'Biteship') && (
                                                    <>
                                                        <tr>
                                                            <td className="cart_total_label" colSpan={2}>
                                                                <h6>Lokasi Pengiriman</h6>
                                                            </td>
                                                            <td className="cart_total_amount">
                                                                <a href={'./location'}><h6 className="text-brand text-end">Pilih Lokasi</h6></a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="cart_total_label" colSpan={3}>
                                                                <p align="justify">
                                                                    {this.state.orderLocPinPoint}<br/><br/>
                                                                    Jarak dengan gudang: <strong>{(parseFloat(this.state.orderLocJarak)/1000).toFixed(2)} Km</strong>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        {
                                                            this.state.orderDeliveryType == 'Jasa Kurir' && (
                                                                <tr>
                                                                    <td className="cart_total_label" colSpan={3}>
                                                                        <div className="alert alert-success">
                                                                            Gratis biaya pengiriman dengan minimal total belanja di keranjang sebesar satu juta rupiah.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            this.state.orderDeliveryType == 'Biteship' && (
                                                                <>
                                                                    <tr>
                                                                        <td scope="col" colSpan={3}>
                                                                            <div className="divider-2 mt-10 mb-10"></div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="3">
                                                                            <div className="table-responsive">
                                                                                <table className="table no-border">
                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td className="cart_total_label" colSpan={2}>
                                                                                            <h6 className="text-muted">Pilih Kurir Pengiriman</h6>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {
                                                                                        this.state.generateShipmentMethod
                                                                                    }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                            <tr>
                                                <td scope="col" colSpan={3}>
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="col" colSpan={3}>
                                                    <div className="input-style">
                                                        <label>Nama Warung (optional)</label>
                                                        <div className="custom_select">
                                                            <select
                                                                className="select-style" onChange={e => this.setState({orderWarung: e.target.value})}>
                                                                <option value={''}>Pilih Warung</option>
                                                                {
                                                                    this.state.warungData.map((object, i) => {
                                                                        return <option value={object.KodeWarung}>{object.KodeWarung + ' - ' + object.NamaWarung}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="border p-md-4 cart-totals ml-30 mb-15">
                                    <div className="table-responsive">
                                        <table className="table no-border">
                                            <tbody>
                                            <tr>
                                                <td className="cart_total_label" colSpan={3}>
                                                    <h6 className="text-muted">Metode Pembayaran</h6>
                                                </td>
                                            </tr>
                                            {
                                                this.state.generatePaymentMethod
                                            }
                                            <tr id="koinworks-tenor" style={{'display': 'none'}}>
                                                <td scope="col" colSpan={3}>
                                                    <div className="input-style">
                                                        <label>Pilih Tenor</label>
                                                        <div className="custom_select">
                                                            <select
                                                                className="select-style" onChange={e => this.setState({koinWorksTenor: e.target.value})}>
                                                                {
                                                                    Helpers.arrKoinWorksTenor.map((object, i) => {
                                                                        return <option value={object.value}>{object.label}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="border p-md-4 cart-totals ml-30">
                                    <div className="table-responsive">
                                        <table className="table no-border">
                                            <tbody>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Total Keranjang</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h4 className="text-brand text-end">{this.state.orderTotal.toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Total Estimasi Komisi</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h6 className="text-heading text-end">{this.state.orderTotalKomisi}</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Total Berat</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h6 className="text-heading text-end">{this.state.orderTotalBerat} gram</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="col" colSpan="2">
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Pengiriman</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h5 className="text-brand text-end">{parseInt(this.state.orderLocJasaKirim).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h5></td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Biaya Admin</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h5 className="text-brand text-end">{parseInt(this.state.orderPaymentCharge).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h5></td>
                                            </tr>
                                            <tr>
                                                <td scope="col" colSpan="2">
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Gunakan Waroq Poin</h6>
                                                </td>
                                                <td className="cart_total_amount" align="right">
                                                    <input className="form-check-input" type="checkbox"
                                                           checked={this.state.isCommissionChecked}
                                                           onClick={() => {this.handleCommissionChecked()}}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label text-align-left">
                                                    <h6 style={{'color': '#253D4E'}}>Saldo: {parseInt(this.state.saldoCommission).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h4 className="text-brand text-end">{parseFloat(this.state.orderKomisiDigunakan).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="col" colSpan="2">
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Gunakan BBX Poin</h6>
                                                </td>
                                                <td className="cart_total_amount" align="right">
                                                    {
                                                        !this.state.sessKodeNasabah && (
                                                            <a href={'./bbx-poin-login?redirectUrl='+ base64_encode(window.location.href)}>Hubungkan</a>
                                                        )
                                                    }
                                                    {
                                                        this.state.sessKodeNasabah && (
                                                            <input className="form-check-input" type="checkbox"
                                                                   checked={this.state.isBBXPoinChecked}
                                                                   onClick={() => {this.handleBBXPoinChecked()}}/>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label text-align-left">
                                                    <h6 style={{'color': '#253D4E'}}>Saldo: {parseInt(this.state.saldoBBXPoin).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h4 className="text-brand text-end">{parseFloat(this.state.orderBBXPoinDigunakan).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h4>
                                                </td>
                                            </tr>
                                            {
                                                this.state.isBBXPoinChecked && (
                                                    <tr>
                                                        <td scope="col" colSpan="2">
                                                            <div className="contact-form-style">
                                                                <div className="input-style">
                                                                    <input value={this.state.password_waroq} onChange={e => this.setState({password_waroq: e.target.value})}
                                                                           placeholder="Masukkan Password Waroq"
                                                                           type="password"/>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            <tr>
                                                <td scope="col" colSpan="2">
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Grand Total</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h4 className="text-brand text-end">{parseFloat(this.state.orderGrandTotal).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h4>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <a onClick={() => {this.handleUpload()}} className="btn mb-20 w-100">Submit Order<i className="fi-rs-sign-out ml-15"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default Checkout;