import React from "react";
import {ReactSession} from "react-client-session";

import { Helpers } from "../../config/Helpers";
import {toast} from "react-toastify";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import $ from "jquery";
import queryString from "query-string";

class ChangePaymentMethod extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessKodeNasabah: (ReactSession.get("sess_kodenasabah")) ? ReactSession.get("sess_kodenasabah") : '',
            summaryData: '',
            paymentData: [],
            warungData: [],
            orderData: [],
            orderTotal: 0,
            orderGrandTotal: 0,
            orderTotalKomisi: 0,
            orderTotalBerat: 0,
            orderKomisiDigunakan: 0,
            orderDeliveryType: (ReactSession.get("sess_order_delivery")) ? ReactSession.get("sess_order_delivery") : 'Ambil Sendiri',
            orderDeliveryLocation: (ReactSession.get("sess_order_kodelokasi")) ? ReactSession.get("sess_order_kodelokasi") : '',
            orderPaymentMethod: '',
            orderPaymentMethodType: '',
            orderPaymentCharge: 0,
            orderPaymentFee: '',
            orderWarung: '',
            orderType: (ReactSession.get("order_type")) ? ReactSession.get("order_type") : 'Grosir',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            orderLocPinPoint: (ReactSession.get("sess_order_pinpoint")) ? ReactSession.get("sess_order_pinpoint") : '',
            orderLocJarak: (ReactSession.get("sess_order_jarak")) ? ReactSession.get("sess_order_jarak") : '0',
            orderLocJasaKirim: (ReactSession.get("sess_order_jasakirim")) ? ReactSession.get("sess_order_jasakirim") : '0',
            isCommissionChecked: false,
            saldoCommission: 0,
            typeCommission: 'Pilih',
            urlParam: queryString.parse(window.location.search),
            kodeMarketPlaceDetail: '',
            orderShipmentMethod: '',
            koinWorksTenor: '30',
            kodeTransaksiOrder: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            const kodeOrder = (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : '';

            this.setState({
                kodeTransaksiOrder: kodeOrder
            }, () => {
                this.getOrderDetail();
                this.getSummary();
            });
        }
    }

    getSummary = () => {
        Helpers.getSummary(this.state.sessUserCode, this.state.sessKodeNasabah).then(res => {
            this.setState({
                summaryData: res[0],
                orderLocJasaKirim: (parseInt(this.state.orderLocJasaKirim) < 10000) ? 10000 : parseInt(this.state.orderLocJasaKirim)
            }, () => {
                this.callPaymentMethod();
            });
        })
    };

    getOrderDetail = () => {
        Helpers.getListOrder('yes',this.state.kodeTransaksiOrder,'','','').then(res => {
            const convertTotal = parseInt(res[0].TotalHargaOrder.replaceAll(".", ""));
            const convertBiayaAdmin = parseInt(res[0].BiayaAdmin.replaceAll(".", ""));
            const convertResult = convertTotal-convertBiayaAdmin;

            console.log(res[0].TotalHargaOrder);
            console.log(res[0].TotalHargaOrder.replaceAll(".", ""));
            console.log(convertTotal);
            console.log(convertBiayaAdmin);
            console.log(convertResult);

            this.setState({
                convertResult: convertResult.toString()
            })
        })
    };

    callPaymentMethod = () => {
        Helpers.getListPaymentChannel('patungan').then(res => {
            const generatePaymentMethod = res.map((object, i) => {
                return <tr>
                    <td>
                        <input type="radio" style={{'height': '11px', width: '11px'}} value={object.KodePaymentGateway} name="paymentmethod" checked={((this.state.orderPaymentMethod == object.KodePaymentGateway) ? 'checked' : '')} onChange={() => {this.handleRadioPaymentChange(object)}}/>
                    </td>
                    <td className="image product-thumbnail"><img
                        src={object.Logo} alt="#"/></td>
                    <td>
                        <h6 className="text-brand text-align-left" style={{'vertical-align': 'unset'}}>{object.Shortname}</h6>
                        <h6 className="w-160 mb-5 text-align-left">
                            {
                                object.KodePaymentGateway == 'Limit' && (
                                    <a href="#" className="text-heading">Saldo: Rp {this.state.summaryData.credit_limit}</a>
                                )
                            }
                            {
                                object.KodePaymentGateway == 'Saldo' && (
                                    <a href="#" className="text-heading">Saldo: Rp {this.state.summaryData.saldo_waroq}</a>
                                )
                            }
                            {
                                object.KodePaymentGateway == 'KPG-0017' && (
                                    <a href="#" className="text-heading">Saldo: Rp {this.state.summaryData.KoinWorksLimitFormat}</a>
                                )
                            }
                            {
                                (object.KodePaymentGateway !== 'Limit' && object.KodePaymentGateway !== 'Saldo' && object.KodePaymentGateway !== 'KPG-0017') && (
                                    <a href="#" className="text-heading">{object.Fullname}</a>
                                )
                            }
                        </h6>
                    </td>
                </tr>
            })

            this.setState({
                paymentData: res,
                generatePaymentMethod
            })
        })
    }

    calculateTotal = () => {
        let jasa_kirim = parseInt(this.state.convertResult);
        let check_total_komisi = 0;
        let total_harga_kj = 0 + jasa_kirim;
        let sisa_komisi = 0;
        let payment_fee = 0;
        let jenis_komisi = '';
        let MetodePembayaran = '';
        let IDPayment = '';
        let komisi_digunakan = 0;
        let sum_total_payment = 0;

        jenis_komisi = "Pilih";

        if(this.state.orderPaymentFee == ''){
            MetodePembayaran = "";
            IDPayment = "";
            sisa_komisi = check_total_komisi;
            komisi_digunakan = 0;
            payment_fee = 0;
        }else{
            MetodePembayaran = this.state.orderPaymentMethodType;
            IDPayment = this.state.orderPaymentMethod;

            sisa_komisi = check_total_komisi;
            komisi_digunakan = 0;

            sum_total_payment = total_harga_kj - komisi_digunakan;
            if(this.state.orderPaymentMethodType == 'e-Money'){
                payment_fee = (parseFloat(this.state.orderPaymentFee)/100)*parseInt(sum_total_payment);
            }else if(this.state.orderPaymentMethodType == 'Credit Card'){
                payment_fee = ((parseFloat(this.state.orderPaymentFee)/100) * (parseInt(sum_total_payment)+5000)) + 6500;
            }else{
                payment_fee = parseInt(this.state.orderPaymentFee);
            }
        }

        let total_harga_by_flow = total_harga_kj + payment_fee - komisi_digunakan;

        this.setState({
            typeCommission: jenis_komisi,
            orderPaymentMethodType: MetodePembayaran,
            orderPaymentMethod: IDPayment,
            orderGrandTotal: total_harga_by_flow,
            orderPaymentCharge: payment_fee,
            orderKomisiDigunakan: komisi_digunakan,
            saldoCommission: sisa_komisi,
            orderLocJasaKirim: jasa_kirim
        });
    }

    handleRadioPaymentChange = (object) => {
        this.setState({
            orderPaymentMethod: object.KodePaymentGateway,
            orderPaymentFee: object.Fee,
            orderPaymentMethodType: object.JenisBank
        }, () => {
            this.callPaymentMethod();
            this.calculateTotal();
        });

        if(object.KodePaymentGateway === 'KPG-0017'){
            $("#koinworks-tenor").css("display", "");
        }else{
            $("#koinworks-tenor").css("display", "none");
        }
    };

    handleUpload = () => {
        this.handleCheckPayment();
    };

    handleCheckPayment = () => {
        if(this.state.orderPaymentMethodType == 'Limit'){
            if(parseInt(this.state.orderGrandTotal) <= parseInt(this.state.summaryData.credit_limit_raw)){
                this.handleSendUpload();
            }else{
                toast.error("Total order di keranjang tidak boleh melebihi limit yang tersedia");
            }
        }else if(this.state.orderPaymentMethodType == 'Saldo'){
            if(parseInt(this.state.orderGrandTotal) <= parseInt(this.state.summaryData.saldo_waroq_raw)){
                this.handleSendUpload();
            }else{
                toast.error("Total order di keranjang tidak boleh melebihi saldo yang tersedia");
            }
        }else if(this.state.orderPaymentMethodType == 'Bank'){
            this.handleSendUpload();
        }else if(this.state.orderPaymentMethodType == 'e-Money'){
            this.handleSendUpload();
        }else if(this.state.orderPaymentMethodType == 'Credit Card'){
            this.handleSendUpload();
        }else if(this.state.orderPaymentMethodType == 'Fintech'){
            this.handleSendUpload();
        }else{
            toast.error("Metode pembayaran belum dipilih");
        }
    };

    handleSendUpload = () => {
        if(this.state.orderDeliveryType == ''){
            toast.error("Lokasi atau jenis pengiriman belum dipilih");
        }else{
            if(this.state.orderDeliveryType == 'Jasa Kurir' && this.state.orderDeliveryLocation == ''){
                toast.error("Lokasi atau jenis pengiriman belum dipilih");
            }else{
                this.handlePrepareUpload();
            }
        }
    };

    handlePrepareUpload = () => {
        Helpers.changePaymentMethod(
            this.state.kodeTransaksiOrder,
            this.state.sessUserCode,
            this.state.orderPaymentMethodType,
            this.state.orderPaymentMethod
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    setTimeout(() => {
                        if(this.state.orderPaymentMethod === 'Saldo'){
                            window.location.href = './account-orders-details?trxid=' + base64_encode(this.state.kodeTransaksiOrder);
                        }else{
                            window.location.href = './account-orders-payment?trxid=' + base64_encode(this.state.kodeTransaksiOrder) + '&payment=' + base64_encode(this.state.orderPaymentMethod) + '&type=order';
                        }
                    }, 1000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <main className="main">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Ubah Metode Pembayaran
                            </div>
                        </div>
                    </div>
                    <div className="container mb-80 mt-15">
                        <div className="row">
                            <div className="col-lg-5 m-auto">
                                <div className="border p-md-4 cart-totals ml-30 mb-15">
                                    <div className="totall-product mb-10">
                                        <a href={'./account-orders-details?trxid=' + base64_encode(this.state.kodeTransaksiOrder)} className="btn btn-sm">Kembali</a>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table no-border">
                                            <tbody>
                                            <tr>
                                                <td className="cart_total_label" colSpan={3}>
                                                    <h6 className="text-muted">Metode Pembayaran</h6>
                                                </td>
                                            </tr>
                                            {
                                                this.state.generatePaymentMethod
                                            }
                                            <tr id="koinworks-tenor" style={{'display': 'none'}}>
                                                <td scope="col" colSpan={3}>
                                                    <div className="input-style">
                                                        <label>Pilih Tenor</label>
                                                        <div className="custom_select">
                                                            <select
                                                                className="select-style" onChange={e => this.setState({koinWorksTenor: e.target.value})}>
                                                                {
                                                                    Helpers.arrKoinWorksTenor.map((object, i) => {
                                                                        return <option value={object.value}>{object.label}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="border p-md-4 cart-totals ml-30">
                                    <div className="table-responsive">
                                        <table className="table no-border">
                                            <tbody>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Biaya Admin</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h5 className="text-brand text-end">{parseInt(this.state.orderPaymentCharge).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h5></td>
                                            </tr>
                                            <tr>
                                                <td scope="col" colSpan="2">
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Grand Total</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h4 className="text-brand text-end">{parseFloat(this.state.orderGrandTotal).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h4>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <a onClick={() => {this.handleUpload()}} className="btn mb-20 w-100">Submit<i className="fi-rs-sign-out ml-15"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default ChangePaymentMethod;