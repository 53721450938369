export class Connection {
    static DOMAIN = 'waroq.com';
    static BASE_URL = 'https://' + Connection.DOMAIN + '/';
    static BACKEND_URL = Connection.BASE_URL + 'WRQBACKEND/';
    static SVC_URL = Connection.BASE_URL + 'WRQSVC/';
    static SVC_URL_BBX = Connection.BASE_URL + 'BBXSVC/';
    static API_URL = Connection.SVC_URL + 'ServicesV2/BBXService.asmx/';
    static API_URL_BBX = Connection.SVC_URL_BBX + 'ServicesV2/BBXService.asmx/';

    static SOAP_SaveMitraUpload = Connection.API_URL + 'SaveMitraUploadNew';

    static IMG_SRC_PAYMENT = Connection.BASE_URL + 'image/BuktiBayarMarketing/';
    static IMG_SRC_TOPUP = Connection.BASE_URL + 'image/BuktiTopupKredit/';
    static IMG_SRC_MITRA = Connection.BACKEND_URL + 'image/FotoWarung/';
    static IMG_SRC_BACKEND = Connection.BACKEND_URL + 'Images/';
    static IMG_SRC_BANK = Connection.BACKEND_URL + 'Images/bank/';

    static MAPS_URL = 'https://maps.googleapis.com/maps/api/';
    static MAPS_API_KEY = 'AIzaSyC46Bv-D2TjtWw2aeLDCla3Z1hF3WkkaIw';
    static MPAS_DISTANCE_ORIGINS = '-6.213110657838351,106.82010690652227';
    static MAPS_DISTANCE_URL =
        Connection.MAPS_URL + 'distancematrix/json?units=metric&origins='
        + Connection.MPAS_DISTANCE_ORIGINS + '&key=' + Connection.MAPS_API_KEY + '&destinations='
    static MAPS_DETAILS_URL =
        Connection.MAPS_URL + 'geocode/json?key=' + Connection.MAPS_API_KEY + '&latlng='
}
