import React from "react";

import { Helpers } from "../../config/Helpers";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import {toast} from "react-toastify";

class TransferVoucher extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessKodeNasabah: (ReactSession.get("sess_kodenasabah")) ? ReactSession.get("sess_kodenasabah") : '',
            summaryData: '',
            jumlah: '',
            telepon: '',
            password: '',
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getSummary();
        }
    }

    getSummary = () => {
        Helpers.getSummary(this.state.sessUserCode, this.state.sessKodeNasabah).then(res => {
            this.setState({
                summaryData: res[0]
            });
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const saldo = parseInt(this.state.summaryData.saldo_waroq_raw);
        const min_transfer = parseInt(this.state.summaryData.minimal_transfer_voucher);
        const i_jumlah = parseInt(this.state.jumlah);

        if(saldo<min_transfer) {
            toast.error("Saldo voucher tidak mencukupi");
        }else if(i_jumlah>saldo){
            toast.error("Jumlah transfer tidak boleh melebihi saldo tersedia");
        }else if(i_jumlah<min_transfer){
            toast.error("Jumlah transfer tidak memenuhi nilai minimal");
        }else{
            if (window.confirm('Apakah anda yakin akan menghapus alamat ini?')) {
                Helpers.transferVoucher(this.state.sessUserCode, this.state.jumlah, this.state.telepon, this.state.password).then(res => {
                    if(res.length > 0){
                        if(res[0].alert == 'success'){
                            this.setState({
                                jumlah: '',
                                telepon: '',
                                password: ''
                            });

                            this.getSummary();
                        }

                        toast[res[0].alert](res[0].message);
                    }else{
                        toast.error(Helpers.MSG_ERROR);
                    }
                })
            } else {
                console.log('Cancel.');
            }
        }
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Akun <span></span> Transfer Voucher
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-10">

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 m-auto">
                                    <div className="primary-sidebar">
                                        <div className="sidebar-widget widget-store-info mb-30 bg-2 border-0 info-custom">
                                            <div className="vendor-info">
                                                <h4 className="mb-5">
                                                    <a href="#" className="text-heading">
                                                        Syarat & Ketentuan</a>
                                                </h4>

                                                <div className="product-rate-cover mb-15">
                                                </div>

                                                <div className="vendor-des">
                                                    <p className="font-sm text-heading">
                                                        <ol style={{'list-style': 'list-number'}}>
                                                            <li>Minimal transfer adalah Rp <strong>{parseInt(this.state.summaryData.minimal_transfer_voucher).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</strong></li>
                                                            <li>Pastikan data yang anda masukkan sudah sesuai</li>
                                                            <li>Voucher akan diterima maksimal dalam 10 menit</li>
                                                            <li>Setelah data dikirim, permintaan tidak dapat dibatalkan.</li>
                                                        </ol>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <form className="contact-form-style mt-30 mb-30"
                                          method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                        <div className="input-style mb-20">
                                            <strong>Saldo Voucher</strong>
                                            <input value={this.state.summaryData.saldo_waroq}
                                                   placeholder="Saldo Voucher"
                                                   type="text" readOnly={true}/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <strong>Jumlah Transfer</strong>
                                            <input value={this.state.jumlah} onChange={e => this.setState({jumlah: e.target.value})}
                                                   min={0}
                                                   max={parseInt(this.state.summaryData.saldo_waroq_raw)}
                                                   placeholder="Masukkan Jumlah Transfer"
                                                   type="number" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <strong>Nomor Telepon Tujuan</strong>
                                            <input value={this.state.telepon} onChange={e => this.setState({telepon: e.target.value})}
                                                   min={0}
                                                   placeholder="Masukkan Nomor Telepon Tujuan"
                                                   type="number" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <strong>Password</strong>
                                            <input value={this.state.password} onChange={e => this.setState({password: e.target.value})}
                                                   placeholder="Masukkan Password"
                                                   type="password" required/>
                                        </div>
                                        <button className="submit submit-auto-width"
                                                type="submit">Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </>
        );
    }
}

export default TransferVoucher;